// import { Button } from "devextreme-react";

const Pricing = ({ isPadding, moreRef }) => {
  return (
    <div
      className={`px-4 py-12 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 ${
        isPadding && "lg:pt-20"
      }`}
      id='more'
      ref={moreRef}
    >
      <div className='max-w-xl mb-10 md:mx-auto text-center lg:max-w-2xl md:mb-12'>
        <h2 className='max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto'>
          Transparent Pricing
        </h2>
        <p className='text-base text-gray-700 md:text-lg'>
          Pay based on workload posted per time 
        </p>
      </div>
      <div className='grid max-w-md gap-10 row-gap-5 sm:row-gap-10 lg:max-w-screen-md lg:grid-cols-2 sm:mx-auto'>
        <div className='flex flex-col justify-between p-5 bg-gray-100 border rounded shadow-sm'>
          <div className='mb-6'>
            <div className='flex items-center justify-between pb-6 mb-6 border-b border-sky-500'>
              <div>
                <p className='text-lg font-bold tracking-wider uppercase text-sky-500'>
                  Small Task
                </p>
                <p className='text-sm'>1 - 50 Workers </p>
              </div>
            </div>
            <div>
              <p className='mb-2 font-bold tracking-wide'>Pricing</p>
              <ul className='space-y-3'>
                <li className='flex items-center'>
                  <div className='mr-2'>
                    <svg
                      className='w-4 h-4 text-sky-400'
                      viewBox='0 0 24 24'
                      strokeLinecap='round'
                      strokeLidth='2'
                    >
                      <polyline
                        fill='none'
                        stroke='currentColor'
                        points='6,12 10,16 18,8'
                      />
                      <circle
                        cx='12'
                        cy='12'
                        fill='none'
                        r='11'
                        stroke='currentColor'
                      />
                    </svg>
                  </div>
                  <p className='font-medium text-gray-800'>
                    1 – 5 workers - 3CAD/hr up to 100hours per month, other
                    hours will be 2.5CAD
                  </p>
                </li>
                <li className='flex items-center'>
                  <div className='mr-2'>
                    <svg
                      className='w-4 h-4 text-sky-400'
                      viewBox='0 0 24 24'
                      strokeLinecap='round'
                      strokeLidth='2'
                    >
                      <polyline
                        fill='none'
                        stroke='currentColor'
                        points='6,12 10,16 18,8'
                      />
                      <circle
                        cx='12'
                        cy='12'
                        fill='none'
                        r='11'
                        stroke='currentColor'
                      />
                    </svg>
                  </div>
                  <p className='font-medium text-gray-800'>
                    6 – 20 workers - 2.5CAD/hr up to 300hrs per month, other
                    hours will be 2CAD{" "}
                  </p>
                </li>
                <li className='flex items-center'>
                  <div className='mr-2'>
                    <svg
                      className='w-4 h-4 text-sky-400'
                      viewBox='0 0 24 24'
                      strokeLinecap='round'
                      strokeLidth='2'
                    >
                      <polyline
                        fill='none'
                        stroke='currentColor'
                        points='6,12 10,16 18,8'
                      />
                      <circle
                        cx='12'
                        cy='12'
                        fill='none'
                        r='11'
                        stroke='currentColor'
                      />
                    </svg>
                  </div>
                  <p className='font-medium text-gray-800'>
                    21 – 50 workers – 2CAD/hr up to 500hrs per month, other
                    hours will be 1.75CAD{" "}
                  </p>
                </li>
              </ul>
            </div>
          </div>
          {/* <div>
            <Button text='Calculate Price' type='default' className='w-full' />
          </div> */}
        </div>
        <div className='flex flex-col justify-between p-5 bg-gray-900 border rounded shadow-md text-white'>
          <div className='mb-6'>
            <div className='flex items-center justify-between pb-6 mb-6 border-b border-sky-500'>
              <div>
                <p className='text-lg font-bold tracking-wider uppercase text-sky-500'>
                  Big Task
                </p>
                <p className='text-sm'>Above 50 Workers </p>
              </div>
            </div>
            <div>
              <p className='mb-2 font-bold tracking-wide'>Pricing</p>
              <ul className='space-y-3'>
                <li className='flex items-center'>
                  <div className='mr-2'>
                    <svg
                      className='w-4 h-4 text-sky-500'
                      viewBox='0 0 24 24'
                      strokeLinecap='round'
                      strokeLidth='2'
                    >
                      <polyline
                        fill='none'
                        stroke='currentColor'
                        points='6,12 10,16 18,8'
                      />
                      <circle
                        cx='12'
                        cy='12'
                        fill='none'
                        r='11'
                        stroke='currentColor'
                      />
                    </svg>
                  </div>
                  <p className='font-medium text-white'>1.75CAD/hr flat </p>
                </li>
              </ul>
            </div>
          </div>
          {/* <div>
            <Button
              text='Calculate Price'
              //   type='default'
              className='w-full bg-white'
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Pricing;
