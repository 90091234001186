import React from "react";
import { useQuery } from "react-query";
import JobService, { jobServiceQueryKeys } from "../../service/jobService";
import { LoadPanel } from "devextreme-react";
import Alert from "../misc/Alert";
import JobTable from "./job-table";
import { showNotification } from "../../utils/notification";

export default function PublishedJobs() {
  const { error, data, isLoading, isError } = useQuery(
    [jobServiceQueryKeys.GET_JOB_STATUS, "PUBLISHED"],
    async () => JobService.getAllJobs("accepting-application")
  );

  if (isLoading) {
    return <LoadPanel visible />;
  }

  if (isError) {
    showNotification(error, "error");
  }

  return (
    <React.Fragment>
      <div className='content-block'>
        <Alert>
          <span className='text-base'>
            Click on <strong>START JOB</strong> button to mark job as started
            and stop receiving applications for the job.
          </span>
        </Alert>
      </div>
      <div className={"content-block dx-card responsive-paddings"}>
        <JobTable dataSource={data?.data || []} isPublished />
      </div>
    </React.Fragment>
  );
}
