import { useState, useEffect } from "react";

import { NumberBox } from "devextreme-react";
import priceCalculator from "../../utils/priceCalculator";

const Calculator = () => {
  const [calculatorValues, setCalculatorValues] = useState({
    numberOfHours: 8,
    numberOfWorkers: 5,
    pricePerHour: 10,
  });

  const [calculated, setCalculated] = useState({
    total: 427.12,
    grossPayment: 400,
    serviceFee: 24,
    HST: 3.12,
  });

  useEffect(() => {
    setCalculated(priceCalculator(calculatorValues));
  }, [calculatorValues]);

  const onValueChanged = (value, name) => {
    setCalculatorValues((prev) => ({ ...prev, [name]: value }));
  };

  const { numberOfHours, numberOfWorkers, pricePerHour } = calculatorValues;

  return (
    <div className='px-4 py-12 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8'>
      <div className='max-w-xl mb-10 md:mx-auto text-center lg:max-w-2xl md:mb-12'>
        <h2 className='max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto'>
          Price Calculator
        </h2>
        {/* <p className='text-base text-gray-700 md:text-lg text-center'>
          calculate how much 
        </p> */}
      </div>
      <div className='max-w-md lg:max-w-screen-md sm:mx-auto border border-sky-500 p-6 rounded-md shadow-md'>
        <div className='grid lg:grid-cols-2 gap-5'>
          <NumberBox
            label='Total hour(s)'
            labelMode='floating'
            stylingMode='outlined'
            showSpinButtons
            min={1}
            value={numberOfHours}
            onValueChanged={({ value }) =>
              onValueChanged(value, "numberOfHours")
            }
          />
          <NumberBox
            label='Number of workers'
            labelMode='floating'
            stylingMode='outlined'
            showSpinButtons
            min={1}
            value={numberOfWorkers}
            onValueChanged={({ value }) =>
              onValueChanged(value, "numberOfWorkers")
            }
          />

          <NumberBox
            label='Price(CAD/hr)'
            labelMode='floating'
            stylingMode='outlined'
            showSpinButtons
            min={1}
            className='lg:col-span-2'
            value={pricePerHour}
            onValueChanged={({ value }) =>
              onValueChanged(value, "pricePerHour")
            }
          />
        </div>
        <div className='flex flex-col space-y-3 mt-6'>
          <div className='flex items-center justify-between'>
            <span className='text-lg'>Gross payment</span>
            <span className='text-lg'>{calculated.grossPayment} C$</span>
          </div>
          <div className='flex items-center justify-between'>
            <span className='text-lg'>Service fee</span>
            <span className='text-2xl font-bold text-sky-500'>
              {calculated.serviceFee} C$
            </span>
          </div>
          <div className='flex items-center justify-between'>
            <span className='text-lg'>HST (13% of service fee) *</span>
            <span className='text-lg'>{calculated.HST} C$</span>
          </div>
          <div className='flex items-center justify-between'>
            <span className='text-lg'>Total payment</span>
            <span className='text-lg'>{calculated.total} C$</span>
          </div>
        </div>
        <small className='text-center mt-5 block'>
          * HST only applies to selected province and will not be added if not
          applicable to the job province.
        </small>
      </div>
      <div className='max-w-xl mb-10 md:mx-auto text-center lg:max-w-2xl my-8'></div>
    </div>
  );
};

export default Calculator;
