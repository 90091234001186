import React from "react";
import { Calculator } from "../../components";
import Pricing from "../../components/hero/Pricing";
import partnerImg from "../../images/pexels-yan-krukov-7794049.jpg";
import "./home.scss";

export default function Home() {
  return (
    <React.Fragment>
      <h2 className={"content-block"}>Home</h2>
      <div className={"content-block"}>
        <div className={"dx-card"}>
          <img
            className='object-cover w-full h-56 rounded shadow-lg lg:rounded-none lg:shadow-none md:h-96'
            src={partnerImg}
            alt=''
          />
          <Pricing />
          <Calculator />
        </div>
      </div>
    </React.Fragment>
  );
}
