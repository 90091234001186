import React, { useState, useRef, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
} from "devextreme-react/form";
import LoadIndicator from "devextreme-react/load-indicator";
import { useAuth } from "../../contexts/auth";
import logo from "../../images/task_logo.png";

import "./login-form.scss";

export default function LoginForm() {
  const history = useHistory();
  const { signIn } = useAuth();
  const [loading, setLoading] = useState(false);
  const formData = useRef({});

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const { username, password, accountType } = formData.current;
      setLoading(true);
      localStorage.setItem("accountType", accountType);
      const result = await signIn({ username, password, accountType });

      if (!result.success) {
        setLoading(false);
      }
    },
    [signIn]
  );

  const onCreateAccountClick = useCallback(() => {
    history.push("/create-account");
  }, [history]);

  return (
    <form className={"login-form"} onSubmit={onSubmit}>
      <Link to='/info'>
        <img src={logo} alt='' className='h-8 mx-auto mb-4 cursor-pointer' />
      </Link>
      <Form formData={formData.current} disabled={loading} labelMode='floating'>
        <Item
          dataField={"username"}
          editorType={"dxTextBox"}
          editorOptions={userNameEditorOptions}
        >
          <RequiredRule message='Username is required' />
          <Label visible={false} />
        </Item>
        <Item
          dataField={"password"}
          editorType={"dxTextBox"}
          editorOptions={passwordEditorOptions}
        >
          <RequiredRule message='Password is required' />
          <Label visible={false} />
        </Item>
        <Item
          dataField={"accountType"}
          editorType={"dxSelectBox"}
          editorOptions={accountTypeEditorOptions}
        >
          <RequiredRule message='Account type is required' />
          <Label visible={false} />
        </Item>
        <ButtonItem>
          <ButtonOptions
            width={"100%"}
            type={"default"}
            useSubmitBehavior={true}
          >
            <span className='dx-button-text'>
              {loading ? (
                <LoadIndicator width={"24px"} height={"24px"} visible={true} />
              ) : (
                "Sign In"
              )}
            </span>
          </ButtonOptions>
        </ButtonItem>
        <Item>
          <div className={"link"}>
            <Link to={"/forgot-password"}>Forgot password?</Link>
          </div>
        </Item>
        <ButtonItem>
          <ButtonOptions
            text={"Create an account"}
            width={"100%"}
            onClick={onCreateAccountClick}
          />
        </ButtonItem>
      </Form>
    </form>
  );
}

const userNameEditorOptions = {
  stylingMode: "outlined",
  placeholder: "username",
  mode: "text",
};
const passwordEditorOptions = {
  stylingMode: "outlined",
  placeholder: "Password",
  mode: "password",
};

const accountTypeEditorOptions = {
  stylingMode: "outlined",
  placeholder: "Account Type",
  dataSource: [
    { label: "Company", value: "company" },
    { label: "Private Person", value: "individual" },
  ],
  valueExpr: "value",
  displayExpr: "label",
};
// const rememberMeEditorOptions = {
//   text: "Remember me",
//   elementAttr: { class: "form-text" },
// };
