import { withNavigationWatcher } from "./contexts/navigation";
import {
  HomePage,
  TasksPage,
  ProfilePage,
  CreateJobPage,
  JobApplicationsPage,
  DocViewerPage,
  AllJobsPage,
  CompletedJobsPage,
  PaidJobsPage,
  PublishedJobsPage,
  AttendancePage,
  MakePaymentPage,
  UnPublishedJobsPage,
  GeneralSettingsPage,
} from "./pages";
import OnGoingJobsPage from "./pages/tasks/onGoingJobs";

const routes = [
  {
    path: "/tasks",
    component: TasksPage,
  },
  {
    path: "/profile",
    component: ProfilePage,
  },
  {
    path: "/home",
    component: HomePage,
  },
  {
    path: "/create-job",
    component: CreateJobPage,
  },
  {
    path: "/jobs",
    component: AllJobsPage,
  },
  {
    path: "/job-applications",
    component: JobApplicationsPage,
  },
  {
    path: "/doc-view",
    component: DocViewerPage,
  },
  {
    path: "/ongoing-jobs",
    component: OnGoingJobsPage,
  },
  {
    path: "/completed-jobs",
    component: CompletedJobsPage,
  },
  {
    path: "/paid-jobs",
    component: PaidJobsPage,
  },
  {
    path: "/published-jobs",
    component: PublishedJobsPage,
  },
  {
    path: "/un-published-jobs",
    component: UnPublishedJobsPage,
  },
  {
    path: "/attendance",
    component: AttendancePage,
  },
  {
    path: "/payment",
    component: MakePaymentPage,
  },
  {
    path: "/settings",
    component: GeneralSettingsPage,
  },
];

export default routes.map((route) => {
  return {
    ...route,
    component: withNavigationWatcher(route.component),
  };
});
