import React, { useState, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  EmailRule,
} from "devextreme-react/form";
import LoadIndicator from "devextreme-react/load-indicator";
import "./reset-password-form.scss";
import AuthService from "../../service/authService";
import { showNotification } from "../../utils/notification";

const notificationText =
  "We've sent a link to reset your password. Check your inbox.";

export default function ResetPasswordForm(props) {
  const [loading, setLoading] = useState(false);
  const formData = useRef({});

  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
    const { email, accountType } = formData.current;
    setLoading(true);

    try {
      localStorage.setItem("accountType", accountType);
      await AuthService.requestResetPassword({ email, accountType });
      showNotification(notificationText, "success");
      setLoading(false);
    } catch (error) {
      showNotification(error, "error");
      setLoading(false);
    }
  }, []);

  return (
    <form className={"reset-password-form"} onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading}>
        <Item
          dataField={"email"}
          editorType={"dxTextBox"}
          editorOptions={emailEditorOptions}
        >
          <RequiredRule message='Email is required' />
          <EmailRule message='Email is invalid' />
          <Label visible={false} />
        </Item>
        <Item
          dataField={"accountType"}
          editorType={"dxSelectBox"}
          editorOptions={accountTypeEditorOptions}
        >
          <RequiredRule message='Account type is required' />
          <Label visible={false} />
        </Item>
        <ButtonItem>
          <ButtonOptions
            elementAttr={submitButtonAttributes}
            width={"100%"}
            type={"default"}
            useSubmitBehavior={true}
          >
            <span className='dx-button-text'>
              {loading ? (
                <LoadIndicator width={"24px"} height={"24px"} visible={true} />
              ) : (
                "Reset my password"
              )}
            </span>
          </ButtonOptions>
        </ButtonItem>
        <Item>
          <Link to={"/login"} className={"login-link flex justify-center"}>
            Return to Sign In
          </Link>
        </Item>
      </Form>
    </form>
  );
}

const emailEditorOptions = {
  stylingMode: "outlined",
  placeholder: "Email",
  mode: "email",
};

const submitButtonAttributes = { class: "submit-button" };

const accountTypeEditorOptions = {
  stylingMode: "outlined",
  placeholder: "Account Type",
  dataSource: [
    { label: "Company", value: "company" },
    { label: "Private Person", value: "individual" },
  ],
  valueExpr: "value",
  displayExpr: "label",
};
