import React from 'react';

const Image = ({ src, className, width, height, alt, ...props }) => {
  // eslint-disable-next-line

  return (
    <img
      {...props}
      className={className}
      src={src}
      width={width}
      height={height}
      alt={alt}
    />
  );
};

export default Image;
