import API from "../api/apiConfig";

export const authServiceQueryKeys = {
  GET_USER: "GET_USER",
};

const AuthService = {
  loginUser: async (loginDetails) => {
    return await API.post("/auth/login", {
      ...loginDetails,
    });
  },

  createAccount: async (accDetails) => {
    return await API.post("/auth/register", accDetails);
  },

  getUser: async (token) => {
    const accountType = localStorage.getItem("accountType");
    if (token) {
      return await API.get("/auth/me", {
        headers: { token },
        params: { accountType },
      });
    }
    return await API.get("/auth/me", {
      params: { accountType },
    });
  },

  logout: async () => {
    return await API.post("/auth/logout");
  },

  updatePassword: async (data) => {
    return await API.post(`/auth/updatepassword`, data);
  },

  requestResetPassword: async ({ email, accountType }) => {
    return await API.post("/auth/requestresetpassword", { email, accountType });
  },

  resetPassword: async (password, token, accountType) => {
    return await API.put(`/auth/resetpassword/${token}`, {
      password,
      accountType,
    });
  },
};

export default AuthService;
