import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
} from "react";
import { useHistory } from "react-router-dom";
import { provinceMap } from "../constants/data";
import AuthService from "../service/authService";
import {
  removeUserFromLocalStaorage,
  setUserToLocalStorage,
} from "../utils/general";
import { showNotification } from "../utils/notification";
import { useQueryClient } from "react-query";

const initialState = {
  companyName: "",
  email: "",
  numberOfBranches: 1,
  regNumber: "",
  industry: "",
  size: "",
  status: "",
  phone: "",
  address: {
    addressLine: "",
    city: "",
    postalCode: "",
    province: "",
    country: "",
  },
  contactPerson: {
    name: "",
    email: "",
    phone: "",
  },
};

const initialStatePrivatePersonProfile = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  address: {
    addressLine: "",
    city: "",
    postalCode: "",
    province: "",
    country: "Canada",
  },
  idDocURLS: [],
  documentType: "",
  docTypeOther: "",
};

function AuthProvider(props) {
  const [user, setUser] = useState(null);
  const [profileData, setProfileData] = useState(initialState);
  const [privatePersonProfileData, setPrivatePersonProfileData] = useState(
    initialStatePrivatePersonProfile
  );
  const [jobId, setJobId] = useState("");
  // const [loading, setLoading] = useState(false);

  const { push } = useHistory();

  const client = useQueryClient();

  const getUser = async (isErrorNeeded = false) => {
    try {
      const {
        data: { data },
      } = await AuthService.getUser();
      setUser(data);
      if (data.profile) {
        if (data.accountType === "company") {
          setProfileData({
            ...data.profile,
            address: data.profile.contactDetails[0].address,
          });
        } else {
          setPrivatePersonProfileData({ ...data.profile });
        }
      }
    } catch (error) {
      if (isErrorNeeded) {
        showNotification(error, "error");
      }
    }
  };

  useEffect(() => {
    getUser();
    // eslint-disable-next-line
  }, []);

  const signIn = useCallback(async (loginDetails) => {
    try {
      const {
        data: { token, data },
      } = await AuthService.loginUser(loginDetails);
      setUserToLocalStorage(token, data.accountType);
      setUser(data);
      if (data.profile) {
        setProfileData({
          ...data.profile,
          address:
            data.accountType === "company"
              ? data.profile.contactDetails[0].address
              : data.profile.address,
        });
      }
      return {
        success: true,
      };
    } catch (error) {
      showNotification(error, "error");
      return {
        success: false,
      };
    }

    // eslint-disable-next-line
  }, []);

  const signOut = useCallback(async () => {
    try {
      await AuthService.logout();
      removeUserFromLocalStaorage();
      setUser(null);
      setProfileData(initialState);
      client.removeQueries();
    } catch (error) {
      showNotification(error, "error");
    }

    // eslint-disable-next-line
  }, []);

  const createAccount = useCallback(async (accDetails) => {
    try {
      await AuthService.createAccount(accDetails);
      showNotification(
        "Account created successfully. Please login to account.",
        "success"
      );
      push("/login");
      return {
        success: true,
      };
    } catch (error) {
      showNotification(error, "error");

      return {
        success: true,
      };
    }

    // eslint-disable-next-line
  }, []);

  const setCompanyData = (data) => {
    const regInfo = {
      companyName:
        data[0].corporationNames.find((co) => co.CorporationName.current)
          .CorporationName.name || "",
      numberOfBranches: 1,
      regNumber: data[0].corporationId,
      status: data[0].status,
      address: {
        addressLine: data[0].adresses[0].address.addressLine.join(", "),
        city: data[0].adresses[0].address.city,
        postalCode: data[0].adresses[0].address.postalCode,
        province: provinceMap[data[0].adresses[0].address.provinceCode],
        country: "Canada",
      },
    };

    setProfileData((prev) => ({ ...prev, ...regInfo }));
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        signIn,
        signOut,
        createAccount,
        getUser,
        profileData,
        setCompanyData,
        setJobId,
        jobId,
        privatePersonProfileData,
        setPrivatePersonProfileData,
      }}
      {...props}
    />
  );
}

const AuthContext = createContext({});
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
