import React, { useState, useEffect } from "react";
import Form, {
  SimpleItem,
  GroupItem,
  RequiredRule,
  ButtonItem,
  ButtonOptions,
} from "devextreme-react/form";
import { useMutation } from "react-query";
import Dropzone from "react-dropzone-uploader";
import avatar_img from "../../images/avatar.png";
import { useAuth } from "../../contexts/auth";
import { Button, TextBox, LoadPanel } from "devextreme-react";
import { useScreenSize } from "../../utils/media-query";
import ProfileService from "../../service/profileService";
import { industriesList, provinceArray } from "../../constants/data";
import { API_URL } from "../../constants";
import { getToken } from "../../utils/general";
import { showNotification } from "../../utils/notification";

export default function ProfileSettings() {
  const [cooperationId, setCooperationId] = useState("");
  const [isCompanyRegData, setIsCompanyRegData] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);

  const { isLarge } = useScreenSize();

  const createCompanyProfile = useMutation((companyData) => {
    return ProfileService.createProfile(companyData);
  });

  const updateCompanyProfile = useMutation((updateData) => {
    return ProfileService.updateProfile(updateData.data, updateData.id);
  });

  const { user, getUser, profileData, setCompanyData } = useAuth();

  useEffect(() => {
    setIsCompanyRegData(false);
  }, []);

  const getUploadParams = async ({ file }) => {
    let body = new FormData();

    body.append("image", file);

    return {
      url: `${API_URL}/api/v1/user/profile/image`,
      headers: { token: getToken() },
      body,
    };
  };

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, remove }, status) => {
    if (status === "error_upload") {
      showNotification(
        meta ? `${meta.name}, upload failed...` : "Error uploading file",
        "error"
      );
    }

    if (status === "exception_upload") {
      showNotification(
        meta ? `${meta.name}, upload failed...` : "Error uploading file",
        "error"
      );
    }

    if (status === "aborted") {
      showNotification(`${meta.name}, upload failed...`, "error");
    }

    if (status === "done") {
      getUser(true);
      showNotification(`profile image updated successfully`, "success");
      remove();
    }
  };

  const getCompanyInfo = async () => {
    setIsConfirming(true);
    try {
      const { data } = await ProfileService.confirmCompanyReg(cooperationId);
      setIsConfirming(false);
      if (data) {
        if (typeof data[0] === "string") {
          showNotification(data[0], "error");
          setIsCompanyRegData(false);
        } else {
          setCompanyData(data);
          setIsCompanyRegData(true);
          setCooperationId("");
        }
      } else {
        showNotification("Operation failed. Please try again", "error");
        setIsCompanyRegData(false);
      }
    } catch (error) {
      showNotification("Operation failed. Please try again", "error");
      setIsCompanyRegData(false);
      setIsConfirming(false);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const requestData = {
      companyName: profileData.companyName,
      numberOfBranches: profileData.numberOfBranches,
      email: profileData.email,
      phone: profileData.phone,
      regNumber: profileData.regNumber,
      industry: profileData.industry,
      size: profileData.size,
      status: profileData.status,
      contactDetails: [{ address: profileData.address }],
      contactPerson: profileData.contactPerson,
    };
    if (user.profile) {
      await updateCompanyProfile.mutateAsync(
        {
          data: requestData,
          id: user.profile._id,
        },
        {
          onError: (error) => {
            showNotification(error, "error");
          },
          onSettled: () => {
            getUser();
          },
          onSuccess: () => {
            showNotification("Profile updated successfully", "success");
          },
        }
      );
    } else {
      await createCompanyProfile.mutateAsync(requestData, {
        onError: (error) => {
          showNotification(error, "error");
        },
        onSettled: () => {
          getUser();
        },
        onSuccess: () => {
          showNotification("Profile updated successfully", "success");
        },
      });
    }
  };

  return (
    <React.Fragment>
      <LoadPanel
        visible={isConfirming}
        shading
        shadingColor='rgba(0, 0, 0, 0.32)'
      />
      {user.profile || isCompanyRegData ? (
        <>
          {user?.profile ? (
            <div className='content-block flex justify-end gap-2'>
              Profile Code:
              <strong>
                {user.accountType === "company"
                  ? user?.profile?.companyCode
                  : user?.profile?.profileCode}
              </strong>
            </div>
          ) : null}
          <div
            className={
              "content-block dx-card responsive-paddings flex sm:flex-row flex-col items-center sm:space-y-0 space-y-5"
            }
          >
            <div className={"form-avatar w-40 rounded p-2"}>
              <img
                alt={""}
                src={user.profilePicUrl || avatar_img}
                className='w-full'
              />
            </div>
            <Dropzone
              getUploadParams={getUploadParams}
              onChangeStatus={handleChangeStatus}
              canRemove={true}
              maxSizeBytes={7340032}
              multiple={false}
              maxFiles={1}
              inputContent='Click here to change business profile picture (Logo)'
              accept='image/*'
            />
          </div>
          <div className={"content-block dx-card responsive-paddings"}>
            <form onSubmit={onSubmit}>
              <Form
                labelLocation='top'
                labelMode='floating'
                formData={profileData}
                disabled={
                  createCompanyProfile.isLoading ||
                  updateCompanyProfile.isLoading
                }
              >
                <GroupItem caption='Business Details' colCount={3}>
                  <SimpleItem
                    dataField='regNumber'
                    label={{ text: "Registry ID" }}
                    editorOptions={{ stylingMode: "outlined", readOnly: true }}
                  >
                    <RequiredRule />
                  </SimpleItem>
                  <SimpleItem
                    dataField='companyName'
                    label={{ text: "Name" }}
                    editorOptions={{ stylingMode: "outlined", readOnly: true }}
                  >
                    <RequiredRule />
                  </SimpleItem>
                  <SimpleItem
                    dataField='status'
                    editorOptions={{ stylingMode: "outlined", readOnly: true }}
                  >
                    <RequiredRule />
                  </SimpleItem>
                  <SimpleItem
                    dataField='numberOfBranches'
                    editorType={"dxNumberBox"}
                    editorOptions={{
                      stylingMode: "outlined",
                      min: 1,
                      showSpinButtons: true,
                    }}
                  />
                  <SimpleItem
                    dataField='industry'
                    editorType='dxSelectBox'
                    editorOptions={{
                      stylingMode: "outlined",
                      dataSource: industriesList,
                      searchEnabled: true,
                    }}
                  />
                  <SimpleItem
                    dataField='size'
                    editorType={"dxSelectBox"}
                    editorOptions={{
                      stylingMode: "outlined",
                      dataSource: ["1-10", "11-50", "50-100", "Above 100"],
                    }}
                  />
                </GroupItem>
                <GroupItem caption='Contact Details' colCount={2}>
                  <SimpleItem
                    dataField='email'
                    editorOptions={{ stylingMode: "outlined", mode: "email" }}
                  >
                    <RequiredRule />
                  </SimpleItem>
                  <SimpleItem
                    dataField='phone'
                    editorOptions={{ stylingMode: "outlined", mode: "tel" }}
                  >
                    <RequiredRule />
                  </SimpleItem>
                </GroupItem>
                <GroupItem caption='Address' colCount={2}>
                  <SimpleItem
                    dataField='address.addressLine'
                    label={{ text: "Address Line" }}
                    editorOptions={{ stylingMode: "outlined", readOnly: true }}
                  >
                    <RequiredRule />
                  </SimpleItem>
                  <SimpleItem
                    dataField='address.city'
                    label={{ text: "City" }}
                    editorOptions={{ stylingMode: "outlined", readOnly: true }}
                  >
                    <RequiredRule />
                  </SimpleItem>
                  <SimpleItem
                    editorType='dxSelectBox'
                    dataField='address.province'
                    label={{ text: "Province" }}
                    editorOptions={{
                      stylingMode: "outlined",
                      dataSource: provinceArray,
                      readOnly: true,
                    }}
                  >
                    <RequiredRule />
                  </SimpleItem>
                  <SimpleItem
                    dataField='address.postalCode'
                    label={{ text: "Postal Code" }}
                    editorOptions={{ stylingMode: "outlined", readOnly: true }}
                  >
                    <RequiredRule />
                  </SimpleItem>
                  <SimpleItem
                    dataField='address.country'
                    label={{ text: "Country" }}
                    editorOptions={{ stylingMode: "outlined", readOnly: true }}
                  >
                    <RequiredRule />
                  </SimpleItem>
                </GroupItem>
                <GroupItem caption='Contact Person' colCount={2}>
                  <SimpleItem
                    dataField='contactPerson.name'
                    editorOptions={{ stylingMode: "outlined" }}
                  >
                    <RequiredRule />
                  </SimpleItem>
                  <SimpleItem
                    dataField='contactPerson.email'
                    editorOptions={{ stylingMode: "outlined" }}
                  >
                    <RequiredRule />
                  </SimpleItem>
                  <SimpleItem
                    dataField='contactPerson.phone'
                    editorOptions={{ stylingMode: "outlined" }}
                  >
                    <RequiredRule />
                  </SimpleItem>
                </GroupItem>
                <ButtonItem>
                  <ButtonOptions
                    width={"100%"}
                    type={"default"}
                    useSubmitBehavior={true}
                    text='Save'
                  />
                </ButtonItem>
              </Form>
            </form>
          </div>
        </>
      ) : (
        <div
          className={
            "content-block dx-card responsive-paddings flex sm:items-center sm:space-x-6 sm:space-y-0 space-y-3 sm:flex-row flex-col"
          }
        >
          <TextBox
            label='Enter Business Registry ID to Continue'
            labelMode='floating'
            stylingMode='outlined'
            onValueChanged={(e) => setCooperationId(e.value)}
            width={isLarge ? "70%" : "100%"}
            value={cooperationId}
            valueChangeEvent='keyup'
            showClearButton
          />
          <Button
            text='Confirm'
            type='default'
            onClick={getCompanyInfo}
            disabled={cooperationId.trim() ? false : true}
          />
        </div>
      )}
    </React.Fragment>
  );
}
