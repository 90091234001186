import React from "react";
import "./profile.scss";
import { ProfileContainer } from "../../components";
import { useAuth } from "../../contexts/auth";

export default function Profile() {
  const { user } = useAuth();
  return (
    <React.Fragment>
      <h2 className={"content-block"}>
        {user?.accountType === "company" ? "Business Profile" : "Profile"}
      </h2>
      <ProfileContainer />
    </React.Fragment>
  );
}
