import React, { useState, useEffect } from "react";
import { Button, SelectBox } from "devextreme-react";
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  ColumnChooser,
  Lookup,
} from "devextreme-react/data-grid";
import { useQuery, useMutation, useQueryClient } from "react-query";
import JobService, { jobServiceQueryKeys } from "../../service/jobService";
import { LoadPanel } from "devextreme-react";
import { Badge } from "../misc/Badge";
import { appliedJobStatus } from "../../constants/data";
import ApplicationDetials from "./application-details";
import { useLocation } from "react-router-dom";
import { showNotification } from "../../utils/notification";
import { alert, confirm } from "devextreme/ui/dialog";
import ConfirmDays from "./confirm-days";
import Alert from "../misc/Alert";

// email: string;
//   phone: string;
//   firstName: string;
//   lastName: string;

export default function JobApplications() {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [inputValue, setInputValue] = useState(null);
  const [requestJobData, setRequestJobData] = useState(null);
  const [isRequestModal, setIsRequestModal] = useState(false);
  const [acceptedDays, setAcceptedDays] = useState([]);

  const { state } = useLocation();
  const client = useQueryClient();

  const decideApplication = useMutation((request) => {
    return JobService.decideApplication(request.jobId, request.requestData);
  });

  const { error, data, isLoading, isError } = useQuery(
    [jobServiceQueryKeys.GET_JOB_STATUS, "PUBLISHED"],
    async () => JobService.getAllJobs("accepting-application")
  );

  const {
    isError: isDetailError,
    error: detailsError,
    data: jobDetails,
    isLoading: isFetchingDetials,
  } = useQuery(
    [jobServiceQueryKeys.GET_JOB_DETAILS, inputValue?._id],
    async () => JobService.getSingleJob(inputValue?._id),
    {
      enabled: inputValue ? true : false,
    }
  );

  if (isDetailError) {
    showNotification(detailsError, "error");
  }

  if (isError) {
    showNotification(error, "error");
  }

  useEffect(() => {
    if (state && state.docuView) {
      // setApplications(state.applications);
      setInputValue(state.inputValue);
      setSelected(state.selected);
      setRequestJobData(state.requestJobData);
      setIsDetailsOpen(true);
    }
  }, [state]);

  const closeApplicationDetails = () => {
    setIsDetailsOpen(false);
    setSelected(null);
  };

  const closeRequestModal = () => {
    setIsRequestModal(false);
    setAcceptedDays([]);
    // setRequestJobData(null);
  };

  const openRequestModal = () => {
    setIsRequestModal(true);
  };

  const onSelectionChanged = ({ selectedRowsData }) => {
    setAcceptedDays(selectedRowsData);
  };

  const handleDecide = async (request) => {
    if (acceptedDays.length === 0 && !request) {
      alert("No day selected. Please select at least one day", "Notification");
      return;
    }
    const message = request
      ? "Are you sure you want to reject this application ?"
      : "Are you sure you want to accept this application for this day(s) ?";
    const result = await confirm(message, "Confirm");
    if (result) {
      const requestData = {
        userId: request ? request.userId : requestJobData?.userInfo?.userId,
        status: request ? request.status : "accepted",
        acceptedDays,
      };
      await decideApplication.mutateAsync(
        { jobId: inputValue?._id, requestData },
        {
          onError: (error) => {
            showNotification(error, "error");
          },
          onSuccess: () => {
            client.invalidateQueries(
              [jobServiceQueryKeys.GET_JOB_DETAILS, inputValue?._id],
              {
                exact: true,
              }
            );

            setAcceptedDays([]);
            closeRequestModal();
            closeApplicationDetails();
            showNotification("Successful", "success");
          },
        }
      );
    }
  };

  const onToolbarPreparing = (e) => {
    let toolbarItems = e.toolbarOptions.items;
    toolbarItems.forEach(function (item) {
      item.showText = true;

      if (item.name === "searchPanel" || item.name === "columnChooserButton") {
        item.location = "after";
      } else {
        item.location = "before";
      }
    });
  };

  const renderActions = ({ data }) => {
    if (data.applicationStatus === "in-review") {
      return (
        <div className='flex sm:space-x-3 space-x-0 sm:flex-row flex-col sm:space-y-0 space-y-3 justify-center'>
          <Button
            text='Accept'
            type='success'
            onClick={() => {
              setRequestJobData(data);
              setIsRequestModal(true);
            }}
          />
          <Button
            text='Reject'
            type='danger'
            onClick={() => {
              setRequestJobData(null);
              handleDecide({
                userId: data.userInfo.userId,
                status: "rejected",
              });
            }}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  const renderStatus = ({ data }) => {
    // "in-review" | "accepted" | "rejected"
    switch (data.applicationStatus) {
      case "in-review":
        return (
          <Badge className='bg-yellow-700 text-yellow-200'>In Review</Badge>
        );
      case "accepted":
        return <Badge className='text-white bg-green-600'>Accepted</Badge>;
      case "rejected":
        return (
          <Badge
            className=' text-red-200'
            style={{ backgroundColor: "#f44336" }}
          >
            Rejected
          </Badge>
        );
      default:
        return <span>{data.applicationStatus}</span>;
    }
  };

  const renderViewIcon = ({ data }) => {
    return (
      <Button
        icon='pi pi-eye'
        type='default'
        hint='View Application'
        onClick={() => {
          setSelected(data);
          setRequestJobData(data);
          setIsDetailsOpen(true);
        }}
      />
    );
  };

  if (isLoading) {
    return <LoadPanel visible />;
  }

  return (
    <React.Fragment>
      {!isError ? (
        <>
          <div className='content-block'>
            <Alert>
              <span className='text-base'>
                Here you can manage applications for each job and make decision
                on application. <strong>Note</strong> that only published jobs
                can have application.
              </span>
            </Alert>
          </div>
          <div className={"content-block dx-card responsive-paddings"}>
            <SelectBox
              label='Select a job to see applications'
              labelMode='floating'
              stylingMode='outlined'
              dataSource={data?.data || []}
              displayExpr='title'
              valueChangeEvent='keyup'
              value={inputValue}
              onValueChanged={({ value }) => {
                setInputValue(value);
              }}
            />
          </div>
          {isFetchingDetials && <LoadPanel visible />}
          {jobDetails?.data && !isDetailError ? (
            <div className={"content-block dx-card responsive-paddings"}>
              <DataGrid
                dataSource={jobDetails.data.applicants}
                keyExpr='id'
                showBorders
                wordWrapEnabled
                columnAutoWidth
                showColumnLines
                showRowLines
                columnHidingEnabled
                rowAlternationEnabled
                allowColumnResizing
                onToolbarPreparing={onToolbarPreparing}
              >
                <FilterRow visible={true} />
                <SearchPanel visible={true} />
                <HeaderFilter visible={true} />
                <Paging defaultPageSize={10} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[10, 20, 30]}
                  showNavigationButtons={true}
                  showInfo={true}
                />
                <ColumnChooser
                  enabled={true}
                  mode='select'
                  allowSearch={true}
                />
                <Column caption='Name'>
                  <Column dataField='userInfo.firstName' caption='First Name' />
                  <Column dataField='userInfo.lastName' caption='Last Name' />
                </Column>

                <Column caption='Contact'>
                  <Column dataField='userInfo.email' caption='Email' />
                  <Column dataField='userInfo.phone' caption='Phone' />
                </Column>
                <Column
                  dataField='applicationStatus'
                  caption='Application status'
                  alignment='center'
                  cellRender={renderStatus}
                >
                  <Lookup
                    dataSource={appliedJobStatus}
                    valueExpr='value'
                    displayExpr='display'
                  />
                </Column>
                <Column
                  cellRender={renderViewIcon}
                  alignment='center'
                  showInColumnChooser={false}
                  allowSearch={false}
                />
                <Column
                  cellRender={renderActions}
                  alignment='center'
                  showInColumnChooser={false}
                  allowSearch={false}
                />
              </DataGrid>
            </div>
          ) : null}
          {selected && (
            <ApplicationDetials
              visible={isDetailsOpen}
              onHide={closeApplicationDetails}
              selected={selected}
              handleDecide={handleDecide}
              requestJobData={requestJobData}
              // applications={applications}
              inputValue={inputValue}
              openRequestModal={openRequestModal}
            />
          )}
          {requestJobData && (
            <ConfirmDays
              visible={isRequestModal}
              onHide={closeRequestModal}
              appliedDays={requestJobData.appliedDays}
              handleDecide={handleDecide}
              onSelectionChanged={onSelectionChanged}
            />
          )}
        </>
      ) : null}
    </React.Fragment>
  );
}
