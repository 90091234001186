import React, { useCallback, useState, useRef, useMemo } from "react";
import Form, {
  SimpleItem,
  GroupItem,
  RequiredRule,
  ButtonItem,
  ButtonOptions,
} from "devextreme-react/form";
import { useMutation } from "react-query";
import Dropzone from "react-dropzone-uploader";
import avatar_img from "../../images/avatar.png";
import { useAuth } from "../../contexts/auth";
import { API_URL } from "../../constants";
import { getToken } from "../../utils/general";
import { showNotification } from "../../utils/notification";
import Alert from "../misc/Alert";
import ProfileDocData from "./ProfileDocData";
import { alert } from "devextreme/ui/dialog";
import ProfileService from "../../service/profileService";
import { provinceArray } from "../../constants/data";

export const documentTypeData = [
  "Drivers' license",
  "Student visa",
  "Work permit",
  "PR card",
  "Bio data page of international passport",
];

export default function PrivatePersonProfile() {
  const [isChanged, setIsChange] = useState(false);

  const formRef = useRef(null);

  const createAccountProfile = useMutation((profileData) => {
    return ProfileService.createProfile(profileData);
  });

  const updateAccountProfile = useMutation((updateData) => {
    return ProfileService.updateProfile(updateData.data, updateData.id);
  });

  const {
    user,
    getUser,
    privatePersonProfileData,
    setPrivatePersonProfileData,
  } = useAuth();

  const getUploadParams = useCallback(
    async ({ file }, type) => {
      let body = new FormData();

      if (type === "FILE") {
        let type = privatePersonProfileData.documentType;

        if (privatePersonProfileData.documentType === "Other") {
          type = privatePersonProfileData.docTypeOther;
        }

        body.append("file", file);
        body.append("type", type);
        body.append("fileName", file.name);

        return {
          url: `${API_URL}/api/v1/user/profile/file`,
          headers: { token: getToken() },
          body,
        };
      } else {
        body.append("image", file);

        return {
          url: `${API_URL}/api/v1/user/profile/image`,
          headers: { token: getToken() },
          body,
        };
      }
    },
    [privatePersonProfileData]
  );

  // called every time a file's `status` changes
  const handleChangeStatus = useCallback(
    ({ meta, remove, xhr }, status, _, type) => {
      if (status === "error_upload") {
        showNotification(
          meta ? `${meta.name}, upload failed...` : "Error uploading file",
          "error"
        );
      }

      if (status === "exception_upload") {
        showNotification(
          meta ? `${meta.name}, upload failed...` : "Error uploading file",
          "error"
        );
      }

      if (status === "aborted") {
        showNotification(`${meta.name}, upload failed...`, "error");
      }

      if (status === "done") {
        if (type === "FILE") {
          const { data } = JSON.parse(xhr.response);
          setPrivatePersonProfileData((pre) => ({
            ...pre,
            idDocURLS: [...pre.idDocURLS, data],
          }));
          showNotification(`${meta.name} uploaded successfully`, "success");
        } else {
          getUser(true);
          showNotification(`profile image updated successfully`, "success");
        }
        remove();
      }
    },
    [setPrivatePersonProfileData, getUser]
  );

  const onFieldDataChanged = (e) => {
    if (e.dataField === "documentType" || e.dataField === "docTypeOther") {
      setIsChange(!isChanged);
    }
  };

  const renderDocUpload = useCallback(() => {
    return (
      <div className='mt-3'>
        <Dropzone
          getUploadParams={(fileWithMeta) =>
            getUploadParams(fileWithMeta, "FILE")
          }
          onChangeStatus={(fileWithMeta, status, fileWithMetaArray) =>
            handleChangeStatus(fileWithMeta, status, fileWithMetaArray, "FILE")
          }
          canRemove={true}
          multiple={false}
          maxSizeBytes={10485760}
          maxFiles={1}
          inputContent='Drag & Drop your file here or click to browse'
          disabled={
            !privatePersonProfileData.documentType ||
            (privatePersonProfileData.documentType === "Other" &&
              !privatePersonProfileData.docTypeOther)
          }
        />
      </div>
    );
  }, [privatePersonProfileData, handleChangeStatus, getUploadParams]);

  const onSubmit = async (e) => {
    e.preventDefault();
    const validationResult = formRef.current?.instance?.validate();

    if (validationResult && !validationResult.isValid) {
      return;
    }

    if (!privatePersonProfileData.idDocURLS.length) {
      alert("Please add at least one identification document", "Notification");
      return;
    }

    if (user.profile) {
      await updateAccountProfile.mutateAsync(
        {
          data: privatePersonProfileData,
          id: user.profile._id,
        },
        {
          onError: (error) => {
            showNotification(error, "error");
          },
          onSettled: () => {
            getUser();
          },
          onSuccess: () => {
            showNotification("Profile updated successfully", "success");
          },
        }
      );
    } else {
      await createAccountProfile.mutateAsync(privatePersonProfileData, {
        onError: (error) => {
          showNotification(error, "error");
        },
        onSettled: () => {
          getUser();
        },
        onSuccess: () => {
          showNotification("Profile updated successfully", "success");
        },
      });
    }
  };

  const renderProfileStatus = useMemo(() => {
    if (user?.profile?.profileStatus === "pending") {
      return (
        <div className={"content-block"}>
          <Alert className='!bg-yellow-200 !text-yellow-900'>
            <span className='text-base'>
              Profile is awaiting verification. Our team is working on that. It
              usually takes 1 working day if all documents are correct.
            </span>
          </Alert>
        </div>
      );
    }

    if (user?.profile?.profileStatus === "de-activated") {
      return (
        <div className={"content-block"}>
          <Alert className='!bg-red-300 !text-red-800'>
            <span className='text-base'>
              Profile is currently de-activated. Please contact the support team
              for help.
            </span>
          </Alert>
        </div>
      );
    }

    if (user?.profile?.profileStatus === "active") {
      return (
        <div className={"content-block"}>
          <Alert className='!bg-green-300 !text-green-800'>
            <span className='text-base'>
              Profile is active and verified. You can start creating jobs.
            </span>
          </Alert>
        </div>
      );
    }

    return null;
  }, [user]);

  return (
    <>
      {renderProfileStatus}
      {user?.profile ? (
        <div className='content-block flex justify-end gap-2'>
          Profile Code:
          <strong>
            {user.accountType === "company"
              ? user?.profile?.companyCode
              : user?.profile?.profileCode}
          </strong>
        </div>
      ) : null}
      <div
        className={
          "content-block dx-card responsive-paddings flex sm:flex-row flex-col items-center sm:space-y-0 space-y-5"
        }
      >
        <div className={"form-avatar w-40 rounded p-2"}>
          <img
            alt={""}
            src={user.profilePicUrl || avatar_img}
            className='w-full'
          />
        </div>
        <Dropzone
          getUploadParams={(fileWithMeta) =>
            getUploadParams(fileWithMeta, "IMAGE")
          }
          onChangeStatus={(fileWithMeta, status, fileWithMetaArray) =>
            handleChangeStatus(fileWithMeta, status, fileWithMetaArray, "IMAGE")
          }
          canRemove={true}
          maxSizeBytes={7340032}
          multiple={false}
          maxFiles={1}
          inputContent='Click here to change profile picture'
          accept='image/*'
        />
      </div>
      <div className={"content-block dx-card responsive-paddings"}>
        <form onSubmit={onSubmit}>
          <Form
            labelLocation='top'
            labelMode='floating'
            formData={privatePersonProfileData}
            onFieldDataChanged={onFieldDataChanged}
            ref={formRef}
          >
            <GroupItem caption='Personal Details' colCount={3}>
              <SimpleItem
                dataField='firstName'
                editorOptions={{ stylingMode: "outlined" }}
              >
                <RequiredRule />
              </SimpleItem>
              <SimpleItem
                dataField='lastName'
                editorOptions={{ stylingMode: "outlined" }}
              >
                <RequiredRule />
              </SimpleItem>
              <SimpleItem
                dataField='email'
                editorOptions={{ stylingMode: "outlined", mode: "email" }}
              >
                <RequiredRule />
              </SimpleItem>
              <SimpleItem
                dataField='phone'
                editorOptions={{ stylingMode: "outlined", mode: "tel" }}
              >
                <RequiredRule />
              </SimpleItem>
            </GroupItem>

            <GroupItem caption='Contact Details' colCount={3}>
              <SimpleItem
                dataField='address.addressLine'
                label={{ text: "Address Line" }}
                editorOptions={{ stylingMode: "outlined" }}
              >
                <RequiredRule />
              </SimpleItem>
              <SimpleItem
                dataField='address.city'
                label={{ text: "City" }}
                editorOptions={{ stylingMode: "outlined" }}
              >
                <RequiredRule />
              </SimpleItem>
              <SimpleItem
                editorType='dxSelectBox'
                dataField='address.province'
                label={{ text: "Province" }}
                editorOptions={{
                  stylingMode: "outlined",
                  dataSource: provinceArray,
                }}
              >
                <RequiredRule />
              </SimpleItem>
              <SimpleItem
                dataField='address.postalCode'
                label={{ text: "Postal Code" }}
                editorOptions={{ stylingMode: "outlined" }}
              >
                <RequiredRule />
              </SimpleItem>
              <SimpleItem
                dataField='address.country'
                label={{ text: "Country" }}
                editorOptions={{ stylingMode: "outlined", readOnly: true }}
              >
                <RequiredRule />
              </SimpleItem>
            </GroupItem>
            <GroupItem caption='Documents'>
              <SimpleItem
                render={() => (
                  <Alert>
                    <span className='text-base'>
                      Please upload at least one valid means of identification
                      from the drop down below. This is needed for your profile
                      verification.s
                    </span>
                  </Alert>
                )}
              />
              <GroupItem colCount={2}>
                <SimpleItem
                  dataField='documentType'
                  editorType='dxSelectBox'
                  helpText='Select document type you wish to upload'
                  editorOptions={{
                    dataSource: documentTypeData,
                    searchEnabled: true,
                    showClearButton: false,
                    stylingMode: "outlined",
                  }}
                />
                {privatePersonProfileData.documentType === "Other" ? (
                  <SimpleItem
                    dataField='docTypeOther'
                    label={{ text: "Please specify" }}
                    editorOptions={{ stylingMode: "outlined" }}
                  >
                    <RequiredRule />
                  </SimpleItem>
                ) : null}
              </GroupItem>
              <SimpleItem render={renderDocUpload} />
            </GroupItem>
            <GroupItem caption='Uploaded Documents'>
              <SimpleItem
                render={() => (
                  <ProfileDocData
                    idDocURLS={privatePersonProfileData.idDocURLS}
                  />
                )}
              />
            </GroupItem>
            <ButtonItem>
              <ButtonOptions
                width={"100%"}
                type={"default"}
                useSubmitBehavior={true}
                text='Save'
              />
            </ButtonItem>
          </Form>
        </form>
      </div>
    </>
  );
}
