import React from "react";
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  ColumnChooser,
  Editing,
  FormItem,
  RequiredRule,
} from "devextreme-react/data-grid";
import {
  parseFormattedDate,
  isValidDateFormatAndReturnFormat,
  convertDateFormat,
} from "../../utils/general";
import { format, differenceInHours, isPast, isToday } from "date-fns";
import { enCA } from "date-fns/locale";
import Alert from "../misc/Alert";

// yyyy-MM-dd

export default function JobDays({
  daysData,
  isEditAble,
  gridRef,
  isEditingJob,
}) {
  const onToolbarPreparing = (e) => {
    let toolbarItems = e.toolbarOptions.items;
    toolbarItems.forEach(function (item) {
      item.showText = true;

      if (item.name === "addRowButton") {
        item.options.hint = "Add Duration";
        item.options.text = "Add Duration";
        item.options.stylingMode = "contained";
        // item.options.type = "default";
        // item.options.icon = "";
      }

      if (item.name === "searchPanel" || item.name === "columnChooserButton") {
        item.location = "after";
      } else {
        item.location = "before";
      }
    });
  };

  return (
    <React.Fragment>
      {isEditAble && (
        <span className='text-base'>
          <span className='text-base text-gray-400'>Job Duration</span>{" "}
          <span className='text-gray-600'>*</span>
        </span>
      )}
      {isEditAble && (
        <Alert>
          <span className='text-base'>
            Please note that all time is rounded to nearest hour. Example:{" "}
            <strong>01:00 PM - 01:30PM = 1hour</strong>
          </span>
        </Alert>
      )}

      <DataGrid
        dataSource={daysData || []}
        ref={gridRef}
        keyExpr='id'
        className={isEditAble ? "mt-5" : ""}
        showBorders
        columnAutoWidth
        showColumnLines
        showRowLines
        columnHidingEnabled
        rowAlternationEnabled
        allowColumnResizing
        wordWrapEnabled
        onToolbarPreparing={onToolbarPreparing}
      >
        {isEditAble && (
          <Editing
            allowAdding={true}
            allowUpdating={true}
            allowDeleting={true}
            mode='form'
          />
        )}

        <FilterRow visible={true} />
        <SearchPanel visible={true} />
        <HeaderFilter visible={true} />
        <Paging defaultPageSize={5} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={[5, 10, 15]}
          showNavigationButtons={true}
          showInfo={true}
        />
        <ColumnChooser enabled={true} mode='select' allowSearch={true} />
        <Column
          dataField='date'
          dataType='date'
          calculateDisplayValue={(rowData) =>
            isEditAble && !isEditingJob
              ? format(rowData.date, "P", {
                  locale: enCA,
                })
              : rowData.date
          }
        >
          <FormItem
            colSpan={2}
            editorType='dxDateBox'
            editorOptions={{
              stylingMode: "outlined",
              // displayFormat: "EEEE, MMM dd",
              useMaskBehavior: true,
              disabledDates: (arg) => {
                return isPast(arg?.date) && !isToday(arg?.date);
              },
              //   labelMode: "floating",
            }}
          />
          <RequiredRule />
        </Column>
        <Column
          dataField='startTime'
          dataType='datetime'
          calculateDisplayValue={(rowData) => {
            if (isEditAble && !isEditingJob) {
              return format(rowData.startTime, "Pp", {
                locale: enCA,
              });
            } else {
              const dateFormat = isValidDateFormatAndReturnFormat(
                rowData.startTime
              );
              if (dateFormat === "yyyy/MM/dd HH:mm:ss") {
                return convertDateFormat(rowData.startTime);
              } else {
                return rowData.startTime;
              }
            }
          }}
        >
          {/* <FormItem
            editorType='dxSelectBox'
            editorOptions={{
              stylingMode: "outlined",
              dataSource: getTimeBlocks(),
              displayExpr: "timeString",
              valueExpr: "timeString",
              searchEnabled: true,
            }}
          /> */}
          <FormItem
            colSpan={2}
            editorType='dxDateBox'
            editorOptions={{
              stylingMode: "outlined",
              type: "datetime",
              useMaskBehavior: true,
              displayFormat: "yyyy-MM-dd, h:mm a",
              disabledDates: (arg) => {
                return isPast(arg?.date) && !isToday(arg?.date);
              },
              // showAnalogClock: false,
              //   labelMode: "floating",
            }}
          />
          <RequiredRule />
        </Column>
        <Column
          dataField='endTime'
          dataType='datetime'
          calculateDisplayValue={(rowData) => {
            if (isEditAble && !isEditingJob) {
              return format(rowData.endTime, "Pp", {
                locale: enCA,
              });
            } else {
              const dateFormat = isValidDateFormatAndReturnFormat(
                rowData.endTime
              );
              if (dateFormat === "yyyy/MM/dd HH:mm:ss") {
                return convertDateFormat(rowData.endTime);
              } else {
                return rowData.endTime;
              }
            }
          }}
        >
          {/* <FormItem
            editorType='dxSelectBox'
            editorOptions={{
              stylingMode: "outlined",
              dataSource: getTimeBlocks(),
              displayExpr: "timeString",
              valueExpr: "timeString",
              searchEnabled: true,
              //   labelMode: "floating",
            }}
          /> */}
          <FormItem
            colSpan={2}
            editorType='dxDateBox'
            editorOptions={{
              stylingMode: "outlined",
              type: "datetime",
              useMaskBehavior: true,
              displayFormat: "yyyy-MM-dd, h:mm a",
              disabledDates: (arg) => {
                return isPast(arg?.date) && !isToday(arg?.date);
              },
              // showAnalogClock: false,
              //   labelMode: "floating",
            }}
          />
          <RequiredRule />
        </Column>
        <Column
          dataField='numberOfHours'
          dataType='number'
          allowEditing={false}
          allowEditingWhenInserting={false}
          calculateCellValue={(rowData) => {
            if (rowData.startTime && rowData.endTime) {
              if (isEditAble) {
                const start =
                  typeof rowData.startTime === "string"
                    ? parseFormattedDate(rowData.startTime)
                    : rowData.startTime;
                const end =
                  typeof rowData.endTime === "string"
                    ? parseFormattedDate(rowData.endTime)
                    : rowData.endTime;

                const hoursDifference = differenceInHours(end, start);
                return hoursDifference;
                // return getNumberOfHours(rowData.startTime, rowData.endTime);
              } else {
                return rowData.numberOfHours;
              }
            } else {
              return "";
            }
          }}
        >
          <FormItem
            editorOptions={{
              stylingMode: "outlined",
              readOnly: true,
              disabled: true,
              placeholder:
                "This field is calculated automatically after saving",
            }}
          />
        </Column>
      </DataGrid>
    </React.Fragment>
  );
}
