import React from "react";
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  HeaderFilter,
} from "devextreme-react/data-grid";

export default function AcceptedDays({ data }) {
  return (
    <React.Fragment>
      <span className='mb-5 text-lg text-sky-600 font-bold'>Accepted Days</span>
      <DataGrid
        dataSource={data.data.acceptedDays}
        key='id'
        showBorders
        columnAutoWidth
        showColumnLines
        showRowLines
        columnHidingEnabled
        rowAlternationEnabled
        allowColumnResizing
        wordWrapEnabled
      >
        <FilterRow visible={true} />

        <HeaderFilter visible={true} />
        <Paging defaultPageSize={10} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={[10, 20, 30]}
          showNavigationButtons={true}
          showInfo={true}
        />

        <Column dataField='date' />

        <Column dataField='startTime' dataType='string' />

        <Column dataField='endTime' dataType='string' />
      </DataGrid>
    </React.Fragment>
  );
}
