export const Badge = ({ children, className, ...props }) => {
  return (
    <span
      className={`inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none rounded ${className}`}
      {...props}
    >
      {children}
    </span>
  );
};
