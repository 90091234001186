import ProgressBar from "@badrap/bar-of-progress";
import axios from "axios";
import { API_URL } from "../constants";
import { getToken } from "../utils/general";

const progress = new ProgressBar({
  // The size (height) of the progress bar.
  // Numeric values get converted to px.
  size: 5,

  // Color of the progress bar.
  // Also used for the glow around the bar.
  color: "#00adee",

  // Class name used for the progress bar element.
  className: "z-100",

  // How many milliseconds to wait before the progress bar
  // animation starts after calling .start().
  delay: 80,
});

const API = axios.create({
  baseURL: `${API_URL}/api/v1`,
  withCredentials: true,
  headers: { token: getToken() },
});

API.interceptors.request.use(
  (config) => {
    progress.start();
    return config;
  },
  (error) => {
    progress.finish();
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => {
    progress.finish();
    return response;
  },
  (error) => {
    progress.finish();
    return Promise.reject(error);
  }
);

export default API;
