import React, { useState } from "react";
import { SelectBox, LoadPanel } from "devextreme-react";
import { useQuery } from "react-query";
import Alert from "../misc/Alert";
import WorkingApplicants from "./working-applicants";
import JobService, { jobServiceQueryKeys } from "../../service/jobService";
import { showNotification } from "../../utils/notification";
import { useAuth } from "../../contexts/auth";

export default function JobAttendance() {
  const [inputValue, setInputValue] = useState(null);
  const { error, data, isLoading, isError } = useQuery(
    [jobServiceQueryKeys.GET_JOB_STATUS, "IN-PROGRESS"],
    async () => JobService.getAllJobs("in-progress")
  );

  const { setJobId } = useAuth();

  const {
    isError: isDetailError,
    error: detailsError,
    data: jobDetails,
    isLoading: isFetchingDetials,
  } = useQuery(
    [jobServiceQueryKeys.GET_JOB_DETAILS, inputValue?._id],
    async () => JobService.getSingleJob(inputValue?._id),
    {
      enabled: inputValue ? true : false,
    }
  );

  if (isDetailError) {
    showNotification(detailsError, "error");
  }

  if (isError) {
    showNotification(error, "error");
  }

  if (isLoading) {
    return <LoadPanel visible />;
  }

  return (
    <React.Fragment>
      <div className='content-block'>
        <Alert>
          <span className='text-base'>
            Here, you can manage attendance of workers for each job.{" "}
            <strong>Note</strong> that attendance can only be added to an
            ongoing job.
          </span>
        </Alert>
      </div>
      {!isError ? (
        <>
          <div className={"content-block dx-card responsive-paddings"}>
            <SelectBox
              label='Select a job to add attendance'
              labelMode='floating'
              stylingMode='outlined'
              dataSource={data?.data || []}
              displayExpr='title'
              valueChangeEvent='keyup'
              value={inputValue}
              onValueChanged={({ value }) => {
                setJobId(value._id);
                setInputValue(value);
              }}
            />
          </div>
          {isFetchingDetials && <LoadPanel visible />}
          {jobDetails?.data && !isDetailError ? (
            <div className={"content-block dx-card responsive-paddings"}>
              <WorkingApplicants
                dataSource={jobDetails.data.acceptedApplicants}
              />
            </div>
          ) : null}
        </>
      ) : null}
    </React.Fragment>
  );
}
