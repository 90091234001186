import React, { useRef } from "react";
import { ScrollView, Button } from "devextreme-react";
import { Popup, ToolbarItem } from "devextreme-react/popup";
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  ColumnChooser,
  Selection,
} from "devextreme-react/data-grid";
import Alert from "../misc/Alert";

export default function ConfirmDays({
  visible,
  onHide,
  handleDecide,
  appliedDays,
  onSelectionChanged,
}) {
  const gridRef = useRef(null);

  return (
    <Popup
      visible={visible}
      onHiding={() => {
        if (gridRef.current) {
          gridRef.current.instance.clearSelection();
        }

        onHide();
      }}
      showCloseButton
      title='Applied Days'
      animation={null}
      // className=' z-50'
    >
      <ScrollView height='100%'>
        <Alert>
          <span className='text-base'>
            Please select days you wish to accept for this application.
          </span>
        </Alert>
        <div className='p-4'>
          <DataGrid
            dataSource={appliedDays}
            keyExpr='id'
            showBorders
            columnAutoWidth
            showColumnLines
            showRowLines
            columnHidingEnabled
            rowAlternationEnabled
            allowColumnResizing
            wordWrapEnabled
            onSelectionChanged={onSelectionChanged}
            ref={gridRef}
          >
            <Selection
              mode='multiple'
              selectAllMode='allPages'
              showCheckBoxesMode='always'
            />
            <FilterRow visible={true} />
            <SearchPanel visible={true} />
            <HeaderFilter visible={true} />
            <Paging defaultPageSize={5} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={[5, 10, 15]}
              showNavigationButtons={true}
              showInfo={true}
            />
            <ColumnChooser enabled={true} mode='select' allowSearch={true} />
            <Column dataField='date' />

            <Column dataField='startTime' dataType='string' />

            <Column dataField='endTime' dataType='string' />
          </DataGrid>
        </div>
      </ScrollView>
      <ToolbarItem
        location='after'
        render={() => (
          <Button type='success' text='Accept' onClick={() => handleDecide()} />
        )}
      />
    </Popup>
  );
}
