import "devextreme/dist/css/dx.common.css";
import "./themes/generated/theme.base.css";
import "./themes/generated/theme.additional.css";
import "react-dropzone-uploader/dist/styles.css";
import "primeicons/primeicons.css";
import "react-notifications-component/dist/theme.css";
import React from "react";
import { useLocation } from "react-router-dom";
import "./dx-styles.scss";
import { useAuth } from "./contexts/auth";
import { useScreenSizeClass } from "./utils/media-query";
import Content from "./Content";
import UnauthenticatedContent from "./UnauthenticatedContent";

export default function App() {
  const { user } = useAuth();
  const screenSizeClass = useScreenSizeClass();

  const { pathname } = useLocation();

  const render = () => {
    if (user) {
      return <Content />;
    }

    return <UnauthenticatedContent />;
  };

  return (
    <div
      className={`${screenSizeClass} ${
        pathname === "/" || pathname === "/info" ? "" : "app"
      }`}
    >
      {render()}
    </div>
  );
}

// export default function Root() {
//   const screenSizeClass = useScreenSizeClass();

//   return (
//     <Router>
//       <AuthProvider>
//         <NavigationProvider>
//           <div className={`app ${screenSizeClass}`}>
//             <App />
//           </div>
//         </NavigationProvider>
//       </AuthProvider>
//     </Router>
//   );
// }
