import React from "react";
import { UnPublishedJobs } from "../../components";

export default function UnPublishedJobsPage() {
  return (
    <React.Fragment>
      <h2 className={"content-block"}>Un-published Jobs</h2>
      <UnPublishedJobs />
    </React.Fragment>
  );
}
