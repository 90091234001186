import React from "react";
import { useQuery } from "react-query";
import JobService, { jobServiceQueryKeys } from "../../service/jobService";
import { LoadPanel } from "devextreme-react";
import JobTable from "./job-table";
import { showNotification } from "../../utils/notification";
import { getToken } from "../../utils/general";

export default function AllJobs() {
  const { error, data, isLoading, isError } = useQuery(
    jobServiceQueryKeys.GET_ALL_JOBS,
    async () => JobService.getAllJobs(null, getToken())
  );

  if (isLoading) {
    return <LoadPanel visible />;
  }

  if (isError) {
    showNotification(error, "error");
  }

  return (
    <React.Fragment>
      <div className={"content-block dx-card responsive-paddings"}>
        <JobTable isAllJobs dataSource={data?.data || []} />
      </div>
    </React.Fragment>
  );
}
