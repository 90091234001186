import React from "react";
import { PaidJobs } from "../../components";

export default function PaidJobsPage() {
  return (
    <React.Fragment>
      <h2 className={"content-block"}>Paid Jobs</h2>
      <PaidJobs />
    </React.Fragment>
  );
}
