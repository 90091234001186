import React, { useState, useRef, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  CustomRule,
  PatternRule,
} from "devextreme-react/form";
import LoadIndicator from "devextreme-react/load-indicator";
import AuthService from "../../service/authService";
import { showNotification } from "../../utils/notification";

export default function ChangePasswordForm(props) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const formData = useRef({});

  const { search } = useLocation();

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const { password, accountType } = formData.current;
      setLoading(true);

      try {
        localStorage.setItem("accountType", accountType);
        const token = new URLSearchParams(search).get("token");
        await AuthService.resetPassword(password, token, accountType);
        setLoading(false);
        showNotification("Password reset was successful", "success");
        history.push("/login");
      } catch (error) {
        showNotification(error, "error");
        setLoading(false);
      }
    },
    [history, search]
  );

  const confirmPassword = useCallback(
    ({ value }) => value === formData.current.password,
    []
  );

  return (
    <form onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading} labelMode='floating'>
        <Item
          dataField={"password"}
          editorType={"dxTextBox"}
          editorOptions={passwordEditorOptions}
        >
          <RequiredRule message='Password is required' />
          <PatternRule
            pattern={/^(?=.*[a-zA-Z])(?=.*[0-9]).+$/}
            message='Password must be at least 8 characters long and alphanumeric'
          />

          <Label visible={false} />
        </Item>
        <Item
          dataField={"confirmedPassword"}
          editorType={"dxTextBox"}
          editorOptions={confirmedPasswordEditorOptions}
        >
          <RequiredRule message='Password is required' />
          <CustomRule
            message={"Passwords do not match"}
            validationCallback={confirmPassword}
          />
          <Label visible={false} />
        </Item>
        <Item
          dataField={"accountType"}
          editorType={"dxSelectBox"}
          editorOptions={accountTypeEditorOptions}
        >
          <RequiredRule message='Account type is required' />
          <Label visible={false} />
        </Item>
        <ButtonItem>
          <ButtonOptions
            width={"100%"}
            type={"default"}
            useSubmitBehavior={true}
          >
            <span className='dx-button-text'>
              {loading ? (
                <LoadIndicator width={"24px"} height={"24px"} visible={true} />
              ) : (
                "Continue"
              )}
            </span>
          </ButtonOptions>
        </ButtonItem>
      </Form>
    </form>
  );
}

const passwordEditorOptions = {
  stylingMode: "outlined",
  placeholder: "Password",
  mode: "password",
};
const confirmedPasswordEditorOptions = {
  stylingMode: "outlined",
  placeholder: "Confirm Password",
  mode: "password",
};

const accountTypeEditorOptions = {
  stylingMode: "outlined",
  placeholder: "Account Type",
  dataSource: [
    { label: "Company", value: "company" },
    { label: "Private Person", value: "individual" },
  ],
  valueExpr: "value",
  displayExpr: "label",
};
