import React from "react";
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  ColumnChooser,
  MasterDetail,
} from "devextreme-react/data-grid";
import AcceptedDays from "./accepted-days";

export default function AcceptedUsers({ dataSource }) {
  const onToolbarPreparing = (e) => {
    let toolbarItems = e.toolbarOptions.items;
    toolbarItems.forEach(function (item) {
      item.showText = true;

      if (item.name === "searchPanel" || item.name === "columnChooserButton") {
        item.location = "after";
      } else {
        item.location = "before";
      }
    });
  };
  return (
    <DataGrid
      dataSource={dataSource}
      keyExpr='id'
      showBorders
      wordWrapEnabled
      columnAutoWidth
      showColumnLines
      showRowLines
      columnHidingEnabled
      rowAlternationEnabled
      allowColumnResizing
      cellHintEnabled
      focusedRowEnabled
      onToolbarPreparing={onToolbarPreparing}
    >
      <FilterRow visible={true} />
      <SearchPanel visible={true} />
      <HeaderFilter visible={true} />
      <Paging defaultPageSize={10} />
      <Pager
        showPageSizeSelector={true}
        allowedPageSizes={[5, 10, 20]}
        showNavigationButtons={true}
        showInfo={true}
      />
      <ColumnChooser enabled={true} mode='select' allowSearch={true} />
      <Column caption='Name'>
        <Column dataField='userInfo.firstName' caption='First Name' />
        <Column dataField='userInfo.lastName' caption='Last Name' />
      </Column>

      <Column caption='Contact'>
        <Column dataField='userInfo.email' caption='Email' />
        <Column dataField='userInfo.phone' caption='Phone' />
      </Column>
      <MasterDetail
        enabled={true}
        autoExpandAll={false}
        component={AcceptedDays}
      />
    </DataGrid>
  );
}
