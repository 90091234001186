import axios from "axios";
import API from "../api/apiConfig";

export const profileServiceQueryKeys = {
  CONFIRM_COMPANY_REG: "CONFIRM_COMPANY_REG",
};

const ProfileService = {
  createProfile: async (profileData) => {
    const { data } = await API.post(`/user/profile`, profileData);
    return data;
  },
  updateProfile: async (profileData, id) => {
    const { data } = await API.put(`/user/profile/${id}`, profileData);
    return data;
  },
  deleteFile: async (id) => {
    return await API.delete(`/user/profile/file/${id}`);
  },
  confirmCompanyReg: async (corporationId) => {
    return await axios.get(
      `https://corporations-ised-isde.api.canada.ca/api/v1/corporations/${corporationId}.json`,
      {
        headers: {
          "user-key": process.env.REACT_APP_API_ID,
        },
        withCredentials: false,
      }
    );
  },
};

export default ProfileService;
