import React from "react";
import { JobApplications } from "../../components";
// import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
// import ad from "../../images/Account Settings _ KPS Digital Slack.pdf";

export default function JobApplicationsPage() {
  return (
    <React.Fragment>
      <h2 className={"content-block"}>Job Applications</h2>
      {/* <DocViewer
        pluginRenderers={DocViewerRenderers}
        documents={[{ uri: ad }]}
      /> */}
      <JobApplications />
    </React.Fragment>
  );
}
