const Alert = ({ error, children, className, ...props }) => {
  return (
    <div
      className={`px-6 py-4 border-0 rounded-md relative mb-4 bg-blue-100 text-blue-900 mt-5 ${className}`}
      {...props}
    >
      <div className='flex items-center space-x-2'>
        <span className='text-xl inline-block '>
          <i className='pi pi-info-circle'></i>
        </span>
        {children}
      </div>
    </div>
  );
};

export default Alert;
