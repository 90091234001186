import { ScrollView, Button } from "devextreme-react";
import { Popup, ToolbarItem } from "devextreme-react/popup";
import { confirm } from "devextreme/ui/dialog";
import React from "react";
import { useQueryClient } from "react-query";
import JobService, { jobServiceQueryKeys } from "../../service/jobService";
import { findPercentage, formatCurrency } from "../../utils/general";
import { showNotification } from "../../utils/notification";

const PaymentModal = ({
  visible,
  jobData,
  onHide,
  downloadInvoice,
  closeJobDetails,
}) => {
  const client = useQueryClient();

  const confirmPayment = async (id) => {
    const result = await confirm(
      "Are you sure you want to confirm payment made for this job ?",
      "Confirm"
    );

    if (result) {
      try {
        await JobService.confirmPayment(id);
        showNotification("Successful", "success");
        client.invalidateQueries([
          jobServiceQueryKeys.GET_JOB_STATUS,
          "COMPLETED",
        ]);
        onHide();
        closeJobDetails();
      } catch (error) {
        showNotification(error, "error");
      }
    }
  };

  return (
    <Popup
      visible={visible}
      onHiding={onHide}
      showCloseButton
      title='Payment Details'
      animation={null}
    >
      <ScrollView>
        <div className='px-6 py-2'>
          <div className='border-b pb-4'>
            <p className='text-base font-bold mb-5'>Invoice Details</p>
            <div className='flex items-center space-x-5'>
              <span className='text-base sm:w-96'>Invoice number:</span>
              <strong className='text-base'>{jobData.invoiceNumber}</strong>
            </div>
            <div className='flex items-center space-x-5'>
              <span className='text-base sm:w-96'>Invoice date:</span>
              <span className='text-base'>{jobData.invoiceDate}</span>
            </div>
            <div className='flex items-center space-x-5'>
              <span className='text-base sm:w-96'>Job code:</span>
              <strong className='text-base'>{jobData.jobCode}</strong>
            </div>
            <div className='flex items-center space-x-5'>
              <span className='text-base sm:w-96'>Job title:</span>
              <span className='text-base'>{jobData.title}</span>
            </div>
            <div className='flex items-center space-x-5'>
              <span className='text-base sm:w-96'>Price by hour:</span>
              <span className='text-base'>{jobData.priceByHour}</span>
            </div>
            <div className='flex items-center space-x-5'>
              <span className='text-base sm:w-96'>Total hours:</span>
              <span className='text-base'>{jobData.totalWorkHours}</span>
            </div>
            <div className='flex items-center space-x-5'>
              <span className='text-base sm:w-96'>
                Total work amount ( price by hour * total hours ):
              </span>
              <span className='text-base'>
                {formatCurrency(jobData.totalWorkAmount)}
              </span>
            </div>
            <div className='flex items-center space-x-5'>
              <span className='text-base sm:w-96'>Service fee:</span>
              <span className='text-base'>
                {formatCurrency(jobData.serviceFee)}
              </span>
            </div>

            <div className='flex items-center space-x-5'>
              <span className='text-base sm:w-96'>
                HST ( 13% of service fee ) *:
              </span>
              <span className='text-base'>
                {formatCurrency(
                  jobData.hasHST ? findPercentage(jobData.serviceFee, 13) : 0
                )}
              </span>
            </div>

            <div className='flex items-center space-x-5 mt-8'>
              <span className='text-base sm:w-96 font-bold'>Balance due:</span>
              <strong className='text-base'>
                {formatCurrency(
                  jobData.totalWorkAmount + jobData.serviceFee + jobData.hasHST
                    ? findPercentage(jobData.serviceFee, 13)
                    : 0
                )}
              </strong>
            </div>
            <p className=''>
              Payment is due within 3 working days of completing job.
            </p>
          </div>

          <div className='mt-8'>
            <p className='text-base font-bold mb-5'>
              Payment instruction and options
            </p>
            <span className='font-bold'>Interact payment </span>
            <p className='mb-4 text-base '>
              Interact payment also known as email money transfer –
              <strong> payments@taskpip.com</strong>
            </p>
            <span className='font-bold'>Direct deposit </span>
            <div className='flex items-center space-x-2'>
              <span className='text-base'>Account number:</span>
              <span className='text-base font-bold'> 552360139319</span>
            </div>
            <div className='flex items-center space-x-2'>
              <span className='text-base'>Transit number:</span>
              <span className='text-base font-bold'> 55236</span>
            </div>
            <div className='flex items-center space-x-2'>
              <span className='text-base'>Institution numberr:</span>
              <span className='text-base font-bold'> 002</span>
            </div>
            <p className='mt-4'>
              Please add <strong>{`"Job code"`}</strong> as reference during
              payment.
            </p>
            <p className='mt-4'>
              * HST only applies to selected province and will not be added if
              not applicable to the job province.
            </p>
          </div>
        </div>
      </ScrollView>
      <ToolbarItem
        location='after'
        render={() => (
          <Button
            text='Invoice'
            type='default'
            stylingMode='outlined'
            onClick={() => downloadInvoice(jobData._id)}
          />
        )}
      />
      <ToolbarItem
        location='after'
        render={() => (
          <Button
            text='Mark Payment made'
            type='default'
            onClick={() => confirmPayment(jobData._id)}
          />
        )}
      />
    </Popup>
  );
};

export default PaymentModal;
