import React from "react";
import { GeneralSettings } from "../../components";

export default function Settings() {
  return (
    <React.Fragment>
      <h2 className={"content-block"}>General Settings</h2>
      <GeneralSettings />
    </React.Fragment>
  );
}
