import React from "react";
import { useQuery } from "react-query";
import JobService, { jobServiceQueryKeys } from "../../service/jobService";
import { LoadPanel } from "devextreme-react";
import JobTable from "./job-table";
import { showNotification } from "../../utils/notification";
import Alert from "../misc/Alert";
import { Link } from "react-router-dom";

export default function OngoingJobs() {
  const { error, data, isLoading, isError } = useQuery(
    [jobServiceQueryKeys.GET_JOB_STATUS, "IN-PROGRESS"],
    async () => JobService.getAllJobs("in-progress")
  );

  if (isLoading) {
    return <LoadPanel visible />;
  }

  if (isError) {
    showNotification(error, "error");
  }

  return (
    <React.Fragment>
      <div className='content-block'>
        <Alert>
          <span className='text-base'>
            Here you can manage ongoing jobs and also mark them as completed.
            Click on <strong>COMPLETE JOB</strong> to mark job as completed.
            Remember to add attendance for the workers. Click{" "}
            <Link to='/attendance'>HERE</Link> to add attendance
          </span>
        </Alert>
      </div>
      <div className={"content-block dx-card responsive-paddings"}>
        <JobTable dataSource={data?.data || []} isInProgress />
      </div>
    </React.Fragment>
  );
}
