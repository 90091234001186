import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import ContextMenu, { Position } from "devextreme-react/context-menu";
import List from "devextreme-react/list";
import { useAuth } from "../../contexts/auth";
import avatar from "../../images/avatar.png";
import "./user-panel.scss";

export default function UserPanel({ menuMode }) {
  const { user, signOut } = useAuth();
  const history = useHistory();

  function navigateToProfile() {
    history.push("/profile");
  }
  const menuItems = useMemo(
    () => [
      {
        text: `${
          user?.accountType === "company" ? "Business Profile" : "Profile"
        }`,
        icon: "pi pi-user-edit",
        onClick: navigateToProfile,
      },
      {
        text: "Logout",
        icon: "pi pi-sign-out",
        onClick: signOut,
      },
    ],

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [signOut]
  );

  return (
    <div className={"user-panel"}>
      <div className={"user-info"}>
        {/* <div className={"image-container"}>
         
        </div> */}
        <img
          className='inline object-cover w-8 h-8 rounded-full'
          src={user?.profilePicUrl || avatar}
          alt='Profile'
        />
        <div className={"user-name"}>{user.username}</div>
      </div>

      {menuMode === "context" && (
        <ContextMenu
          items={menuItems}
          target={".user-button"}
          showEvent={"dxclick"}
          width={210}
          cssClass={"user-menu"}
        >
          <Position my={"top center"} at={"bottom center"} />
        </ContextMenu>
      )}
      {menuMode === "list" && (
        <List className={"dx-toolbar-menu-action"} items={menuItems} />
      )}
    </div>
  );
}
