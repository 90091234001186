import React from "react";
import { useQuery } from "react-query";
import JobService, { jobServiceQueryKeys } from "../../service/jobService";
import { LoadPanel } from "devextreme-react";
import JobTable from "./job-table";
import { showNotification } from "../../utils/notification";
import Alert from "../misc/Alert";

export default function PaidJobs() {
  const { error, data, isLoading, isError } = useQuery(
    [jobServiceQueryKeys.GET_JOB_STATUS, "PAID"],
    async () => JobService.getAllJobs("paid")
  );

  if (isLoading) {
    return <LoadPanel visible />;
  }

  if (isError) {
    showNotification(error, "error");
  }

  return (
    <React.Fragment>
      <div className='content-block'>
        <Alert>
          <span className='text-base'>
            Here you can see list of jobs that payment have been made and also
            view payment receipt if payment have been confirmed.
          </span>
        </Alert>
      </div>
      <div className={"content-block dx-card responsive-paddings"}>
        <JobTable dataSource={data?.data || []} paid />
      </div>
    </React.Fragment>
  );
}
