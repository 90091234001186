import React from "react";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  // Summary,
  // TotalItem,
  Pager,
  Paging,
} from "devextreme-react/data-grid";

export default function Attendance({ dataSource }) {
  return (
    <DataGrid
      dataSource={dataSource}
      keyExpr='id'
      showBorders
      columnAutoWidth
      showColumnLines
      showRowLines
      columnHidingEnabled
      rowAlternationEnabled
      allowColumnResizing
      wordWrapEnabled
      cellHintEnabled
    >
      <Paging defaultPageSize={5} />
      <Pager
        showPageSizeSelector={true}
        allowedPageSizes={[5, 10, 15]}
        showNavigationButtons={true}
        showInfo={true}
      />
      <FilterRow visible={true} />
      <HeaderFilter visible={true} />
      <Column dataField='date' dataType='date' />
      <Column dataField='numberOfHours' />
      {/* <Summary>
        <TotalItem
          column='numberOfHours'
          summaryType='sum'
          displayFormat='Total hours: {0}'
        />
      </Summary> */}
    </DataGrid>
  );
}
