import React from "react";
import { Popup, ToolbarItem } from "devextreme-react/popup";
import { ScrollView, Button, LoadPanel } from "devextreme-react";
import Form, { SimpleItem, GroupItem } from "devextreme-react/form";
import { jobStatusArray } from "../../constants/data";
import JobDays from "./jobDays";
import ad from "../../images/ad.svg";
import RichTextEditor from "react-rte";
import { useQuery } from "react-query";
import JobService, { jobServiceQueryKeys } from "../../service/jobService";
import { showNotification } from "../../utils/notification";
import AcceptedUsers from "./accepted-users";
import { useScreenSize } from "../../utils/media-query";
import { alert } from "devextreme/ui/dialog";
// import RichEditor from "../customEditor/ckeditor";
// import { parseTextToHTML } from "../../utils/general";

export default function JobDetails({
  visible,
  onHide,
  publishJob,
  id,
  markJobStarted,
  markJobCompleted,
  openPaymentModal,
}) {
  const { isError, error, data, isLoading } = useQuery(
    jobServiceQueryKeys.GET_JOB_DETAILS,
    async () => JobService.getSingleJob(id),
    {
      enabled: id ? true : false,
      staleTime: 2,
    }
  );

  const { isLarge } = useScreenSize();

  if (error) {
    showNotification(error, "error");
  }

  const renderFullDescription = () => {
    return (
      <>
        <span className='text-base'>
          <span className='text-base text-gray-400'>Full Description</span>{" "}
        </span>
        <RichTextEditor
          readOnly
          value={RichTextEditor.createValueFromString(
            data?.data.fullDescription || "",
            "markdown"
          )}
          editorClassName='editor'
        />
        {/* <RichEditor
          value={parseTextToHTML(data?.data.fullDescription || "")}
          disabled
        /> */}
      </>
    );
  };

  const renderButtonType = () => {
    switch (data?.data.status || "") {
      case "in-progress":
        return (
          <Button
            type='success'
            text='Complete Job'
            className='mr-3'
            onClick={() =>
              markJobCompleted(data?.data._id || id, true, data?.data)
            }
          />
        );

      case "completed":
        return (
          <Button
            type='default'
            text='Make Payment'
            className='mr-3'
            onClick={() => openPaymentModal(data?.data)}
          />
        );

      case "new":
        return (
          <Button
            type='default'
            text='Publish Job'
            stylingMode='outlined'
            className='mr-4'
            onClick={() => {
              if (!data?.data.isTaskApproved) {
                alert(
                  "You cannot publish this job, it is waiting verification.",
                  "Notification"
                );
                return;
              }
              publishJob(data?.data._id || id, true);
            }}
          />
        );

      case "accepting-application":
        return (
          <Button
            type='success'
            text='Start Job'
            className='mr-3'
            onClick={() => {
              if (!data?.data?.applicants?.length) {
                alert(
                  "This job cannot be started. No applications for this job yet.",
                  "Notification"
                );
                return;
              }

              if (!data?.data?.acceptedApplicants?.length) {
                const message = `
                <div> 
                    
                    <span>
                     No applicant accepted for this job yet. ? <br/> 
                     Go to <strong> Job Applications</strong> to manage applications for this job. <br /> 
                    </span>
                    
                </div>
                
                `;
                alert(message, "Notification");
                return;
              }
              markJobStarted(data?.data._id || id, true);
            }}
          />
        );

      default:
        return null;
    }
  };

  return (
    <Popup
      visible={visible}
      onHiding={onHide}
      showCloseButton
      title='Job Details'
      animation={null}
      fullScreen={true}
    >
      <ScrollView height='100%'>
        {isLoading ? (
          <div>
            <LoadPanel visible />
          </div>
        ) : (
          <div></div>
        )}

        <div>
          {!isError && data?.data ? (
            <div>
              <Form
                labelLocation='top'
                labelMode='floating'
                formData={data.data}
                className='mx-4 my-4'
              >
                <SimpleItem
                  render={() => (
                    <div className=' border p-3 rounded w-56'>
                      <img
                        alt={""}
                        src={data.data.jobImgUrl || ad}
                        className='object-cover w-full'
                      />
                    </div>
                  )}
                />
                <GroupItem colCount={3}>
                  <SimpleItem
                    dataField='title'
                    editorOptions={{
                      stylingMode: "outlined",
                      readOnly: true,
                    }}
                  />
                  <SimpleItem
                    dataField='status'
                    editorType='dxSelectBox'
                    editorOptions={{
                      stylingMode: "outlined",
                      readOnly: true,
                      dataSource: jobStatusArray,
                      displayExpr: "display",
                      valueExpr: "value",
                    }}
                  />
                  <SimpleItem
                    dataField='priceByHour'
                    editorType='dxNumberBox'
                    editorOptions={{
                      stylingMode: "outlined",
                      readOnly: true,
                    }}
                  />
                  <SimpleItem
                    dataField='startDate'
                    editorOptions={{
                      stylingMode: "outlined",
                      readOnly: true,
                    }}
                  />
                  <SimpleItem
                    dataField='endDate'
                    editorOptions={{
                      stylingMode: "outlined",
                      readOnly: true,
                    }}
                  />
                  <SimpleItem
                    dataField='numberOfDays'
                    editorType='dxNumberBox'
                    editorOptions={{
                      stylingMode: "outlined",
                      readOnly: true,
                    }}
                  />
                </GroupItem>
                <GroupItem>
                  <SimpleItem
                    dataField='shortDescription'
                    editorType='dxTextArea'
                    editorOptions={{
                      stylingMode: "outlined",
                      readOnly: true,
                      autoResizeEnabled: true,
                    }}
                  />
                </GroupItem>
                <GroupItem>
                  <SimpleItem render={renderFullDescription} />
                </GroupItem>
                <GroupItem caption='Job Location' colCount={2}>
                  <SimpleItem
                    dataField='location.addressLine'
                    label={{ text: "Address Line" }}
                    editorOptions={{
                      stylingMode: "outlined",
                      readOnly: true,
                    }}
                  />
                  <SimpleItem
                    dataField='location.city'
                    label={{ text: "City" }}
                    editorOptions={{
                      stylingMode: "outlined",
                      readOnly: true,
                    }}
                  />
                  <SimpleItem
                    dataField='location.province'
                    label={{ text: "Province" }}
                    editorOptions={{
                      stylingMode: "outlined",
                      readOnly: true,
                    }}
                  />
                  <SimpleItem
                    dataField='location.postalCode'
                    label={{ text: "Postal Code" }}
                    editorOptions={{
                      stylingMode: "outlined",
                      readOnly: true,
                    }}
                  />
                  <SimpleItem
                    dataField='location.country'
                    label={{ text: "Country" }}
                    editorOptions={{
                      stylingMode: "outlined",
                      readOnly: true,
                    }}
                  />
                </GroupItem>
                <GroupItem
                  caption={
                    data.data.status === "in-progress"
                      ? "Accepted Applications"
                      : "Job Duration"
                  }
                >
                  <SimpleItem
                    render={() => {
                      if (data.data.status === "in-progress") {
                        return (
                          <AcceptedUsers
                            dataSource={data.data.acceptedApplicants}
                          />
                        );
                      }

                      return <JobDays daysData={data.data.taskDays} />;
                    }}
                  />
                </GroupItem>
              </Form>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </ScrollView>

      {isLarge && <ToolbarItem location='after' render={renderButtonType} />}
    </Popup>
  );
}
