import { Button } from "devextreme-react";
import React from "react";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { useLocation, useHistory } from "react-router-dom";

export default function DocViewerPage() {
  const { state } = useLocation();
  const { push } = useHistory();
  return (
    <React.Fragment>
      <h2 className={"content-block"}>
        {state.selected
          ? `Document View - (${state?.selected?.userInfo.firstName}
        ${state?.selected?.userInfo.lastName})`
          : "Document View"}
      </h2>
      <div className='content-block dx-card responsive-paddings'>
        <div className='flex items-center justify-between mb-4'>
          <Button
            type='default'
            text='Go Back'
            onClick={() =>
              push({
                pathname: "/job-applications",
                state: {
                  docuView: true,
                  selected: state?.selected,
                  // applications: state?.applications,
                  inputValue: state?.inputValue,
                  requestJobData: state?.requestJobData,
                },
              })
            }
          />
          <Button
            type='normal'
            icon='pi pi-times'
            onClick={() =>
              push({
                pathname: "/job-applications",
                state: {
                  docuView: true,
                  selected: state?.selected,
                  // applications: state?.applications,
                  inputValue: state?.inputValue,
                  requestJobData: state?.requestJobData,
                },
              })
            }
          />
        </div>
        <DocViewer
          pluginRenderers={DocViewerRenderers}
          documents={[{ uri: state?.docUrl }]}
        />
      </div>
    </React.Fragment>
  );
}
