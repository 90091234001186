import React from "react";
import { useQuery } from "react-query";
import JobService, { jobServiceQueryKeys } from "../../service/jobService";
import { LoadPanel } from "devextreme-react";
import JobTable from "./job-table";
import { showNotification } from "../../utils/notification";
import { Link } from "react-router-dom";
import Alert from "../misc/Alert";

export default function CompletedJobs() {
  const { error, data, isLoading, isError } = useQuery(
    [jobServiceQueryKeys.GET_JOB_STATUS, "COMPLETED"],
    async () => JobService.getAllJobs("completed")
  );

  if (isLoading) {
    return <LoadPanel visible />;
  }

  if (isError) {
    showNotification(error, "error");
  }

  return (
    <React.Fragment>
      <div className='content-block'>
        <Alert>
          <span className='text-base'>
            Here you can view invoice and manage payment for compeleted jobs.
            Please make sure that job have been completed. Go{" "}
            <Link to='/ongoing-jobs'>Here </Link>
            to mark job as completed.
          </span>
        </Alert>
      </div>
      <div className={"content-block dx-card responsive-paddings"}>
        <JobTable completed dataSource={data?.data || []} />
      </div>
    </React.Fragment>
  );
}
