import React from "react";
import { useAuth } from "../../contexts/auth";
import ProfileSettings from "./profile";
import PrivatePersonProfile from "./privatePersonProfile";

export default function ProfileContainer() {
  const { user } = useAuth();
  return (
    <>
      {user?.accountType === "company" && <ProfileSettings />}
      {user?.accountType === "individual" && <PrivatePersonProfile />}
    </>
  );
}
