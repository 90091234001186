import API from "../api/apiConfig";

export const jobServiceQueryKeys = {
  GET_ALL_JOBS: "GET_ALL_JOBS",
  GET_JOB_STATUS: "GET_JOB_STATUS",
  GET_JOB_DETAILS: "GET_JOB_DETAILS",
};

const JobService = {
  createJob: async (jobData) => {
    return await API.post("/job", jobData);
  },

  getAllJobs: async (status, token) => {
    if (status) {
      if (token) {
        const { data } = await API.get("/job/user/me", {
          headers: { token },
          params: { status },
        });

        return data;
      } else {
        const { data } = await API.get("/job/user/me", {
          params: { status },
        });

        return data;
      }
    } else {
      if (token) {
        const { data } = await API.get("/job/user/me", {
          headers: { token },
        });

        return data;
      } else {
        const { data } = await API.get("/job/user/me");

        return data;
      }
    }
  },

  getSingleJob: async (id) => {
    const { data } = await API.get(`/job/${id}`);
    return data;
  },

  updateJob: async (jobData, id) => {
    return await API.put(`/job/${id}`, jobData);
  },

  decideApplication: async (jobId, requestData) => {
    return await API.post(`/job/${jobId}/decide`, requestData);
  },

  startJob: async (id) => {
    return await API.post(`/job/${id}/start`);
  },

  addAttendance: async (jobId, requestData) => {
    return await API.post(`/job/${jobId}/attendance`, requestData);
  },

  completeJob: async (jobId, serviceFee) => {
    return await API.post(`/job/${jobId}/complete`, { serviceFee });
  },

  downloadInvoice: async (jobId) => {
    return await API.get(`/job/${jobId}/invoice/download`, {
      responseType: "blob",
    });
  },

  confirmPayment: async (jobId) => {
    return await API.post(`/job/${jobId}/payment`);
  },

  deleteJob: async (jobId) => {
    return await API.delete(`/job/${jobId}`);
  },

  // attendance
};

export default JobService;
