import React from "react";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  MasterDetail,
  Pager,
  Paging,
} from "devextreme-react/data-grid";
import JobDays from "./JobDays";
import Attendance from "./Attendance";

const AcceptedDays = ({ data }) => {
  return (
    <>
      <span className='mb-3 block font-bold'>{`${data.data.userInfo.firstName}'s Accepted Days`}</span>
      <div className='mb-9 pb-9 border-b'>
        <JobDays dataSource={data.data.acceptedDays} />
      </div>
      <span className='mb-3 block font-bold'>
        {`${data.data.userInfo.firstName}'s Attendance (Added by employer)`}
      </span>
      <div className='mb-6'>
        <Attendance dataSource={data.data.attendance} />
      </div>
    </>
  );
};

export default function AcceptedApplicants({ data }) {
  return (
    <>
      <span className='my-3 block font-bold text-lg'>Job Days</span>
      <div className='mb-9 pb-9 border-b'>
        <JobDays dataSource={data.data.taskDays} />
      </div>
      <span className='mb-3 block font-bold text-lg'>Accepted Applicants</span>
      <div className='mb-9'>
        <DataGrid
          dataSource={data.data.acceptedApplicants}
          keyExpr='id'
          showBorders
          columnAutoWidth
          showColumnLines
          showRowLines
          columnHidingEnabled
          rowAlternationEnabled
          allowColumnResizing
          wordWrapEnabled
          cellHintEnabled
          focusedRowEnabled
        >
          <Paging defaultPageSize={5} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[5, 10, 15]}
            showNavigationButtons={true}
            showInfo={true}
          />
          <FilterRow visible={true} />
          <HeaderFilter visible={true} />
          <Column dataField='userInfo.firstName' caption='First name' />
          <Column dataField='userInfo.lastName' caption='Last name' />
          <Column dataField='userInfo.email' caption='Email' />
          <Column dataField='userInfo.phone' caption='phone' />
          {data.data.status === "completed" && (
            <Column
              dataField='totalHours'
              caption='Total worked hours (Comfirmed by employer)'
            />
          )}
          <MasterDetail enabled component={AcceptedDays} />
        </DataGrid>
      </div>
    </>
  );
}
