import React from "react";
import { Button, ScrollView } from "devextreme-react";
import { Popup, ToolbarItem } from "devextreme-react/popup";
import Form, { SimpleItem, GroupItem } from "devextreme-react/form";
import RichTextEditor from "react-rte";
import { useHistory } from "react-router-dom";
import { Badge } from "../misc/Badge";
import { useScreenSize } from "../../utils/media-query";
import JobDays from "./jobDays";
// import RichEditor from "../customEditor/ckeditor";
// import { parseTextToHTML } from "../../utils/general";

export default function ApplicationDetials({
  visible,
  onHide,
  selected,
  // applications,
  inputValue,
  handleDecide,
  requestJobData,
  openRequestModal,
}) {
  const { push } = useHistory();
  const { isLarge } = useScreenSize();

  const renderCoverLetter = () => {
    return (
      <>
        <span className='text-base'>
          <span className='text-base text-gray-400'>Cover Letter</span>{" "}
        </span>
        <RichTextEditor
          readOnly
          value={RichTextEditor.createValueFromString(
            selected.coverLetter || "",
            "markdown"
          )}
          editorClassName='editor'
        />

        {/* <RichEditor
          value={parseTextToHTML(selected.coverLetter || "")}
          disabled
        /> */}
      </>
    );
  };

  const docRender = () => {
    return (
      <>
        <span className='text-base'>
          <span className='text-base text-gray-400'>Attached Documents</span>{" "}
        </span>
        {selected.applicationDocs.map((doc) => {
          return (
            <div
              className='flex justify-start sm:space-y-0 space-y-2 sm:flex-row flex-col my-5 sm:items-center space-x-4 items-start'
              key={doc.URL}
            >
              <div>
                <i className='pi pi-circle-fill text-sky-700'></i>
                <span className='ml-3 text-base'>{doc.type}</span>
              </div>
              <Button
                icon='pi pi-eye'
                type='default'
                hint='View Document'
                onClick={() =>
                  push({
                    pathname: "/doc-view",
                    state: {
                      docUrl: doc.URL,
                      selected,
                      // applications,
                      inputValue,
                      requestJobData,
                    },
                  })
                }
              />
            </div>
          );
        })}
      </>
    );
  };

  const renderStatus = () => {
    switch (selected.applicationStatus) {
      case "in-review":
        return (
          <Badge className='bg-yellow-700 text-yellow-200 mr-8'>
            In Review
          </Badge>
        );
      case "accepted":
        return <Badge className='text-white bg-green-600 mr-3'>Accepted</Badge>;
      case "rejected":
        return (
          <Badge
            className=' text-red-200 mr-3'
            style={{ backgroundColor: "#f44336" }}
          >
            Rejected
          </Badge>
        );
      default:
        return <span>{selected.status}</span>;
    }
  };

  return (
    <React.Fragment>
      <Popup
        title={`Application Details - (${selected.userInfo.firstName} ${selected.userInfo.lastName})`}
        visible={visible}
        onHiding={onHide}
        showTitle={true}
        showCloseButton
        closeOnOutsideClick={!isLarge}
        animation={null}
        fullScreen={true}
      >
        <ScrollView height='100%'>
          <Form
            labelLocation='top'
            labelMode='floating'
            formData={selected}
            className='mx-4'
          >
            <GroupItem caption='Personal Details' colCount={2}>
              <SimpleItem
                dataField='userInfo.firstName'
                label={{ text: "First Name" }}
                editorOptions={{ stylingMode: "outlined", readOnly: true }}
              />
              <SimpleItem
                dataField='userInfo.lastName'
                label={{ text: "Last Name" }}
                editorOptions={{ stylingMode: "outlined", readOnly: true }}
              />
              <SimpleItem
                dataField='userInfo.email'
                label={{ text: "Email" }}
                editorOptions={{ stylingMode: "outlined", readOnly: true }}
              />
              <SimpleItem
                dataField='userInfo.phone'
                label={{ text: "Phone" }}
                editorOptions={{ stylingMode: "outlined", readOnly: true }}
              />
            </GroupItem>
            <GroupItem caption='Application Documents'>
              <SimpleItem render={renderCoverLetter} />
            </GroupItem>
            {selected.applicationDocs.length && (
              <GroupItem>
                <SimpleItem render={docRender} />
              </GroupItem>
            )}
            <GroupItem caption='Applied Days'>
              <SimpleItem
                render={() => <JobDays daysData={selected.appliedDays} />}
              />
            </GroupItem>
          </Form>
        </ScrollView>
        {isLarge && <ToolbarItem location='after' render={renderStatus} />}

        {isLarge && selected.applicationStatus === "in-review" && (
          <ToolbarItem
            location='after'
            render={() => (
              <Button
                type='success'
                className='mr-2'
                text='Accept'
                onClick={() => openRequestModal()}
              />
            )}
          />
        )}
        {isLarge && selected.applicationStatus === "in-review" && (
          <ToolbarItem
            location='after'
            render={() => (
              <Button
                type='danger'
                className='mr-3'
                text='Reject'
                onClick={() =>
                  handleDecide({
                    userId: requestJobData.userInfo.userId,
                    status: "rejected",
                  })
                }
              />
            )}
          />
        )}
      </Popup>
    </React.Fragment>
  );
}
