import React from "react";
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  HeaderFilter,
  Editing,
  RequiredRule,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import { useMutation, useQueryClient } from "react-query";
import { useAuth } from "../../contexts/auth";
import JobService, { jobServiceQueryKeys } from "../../service/jobService";
import { showNotification } from "../../utils/notification";

export default function AttandanceTable({ data }) {
  const { jobId } = useAuth();

  const addAttendance = useMutation((requestData) =>
    JobService.addAttendance(jobId, requestData)
  );

  const client = useQueryClient();

  const onRowUpdating = async ({ newData, oldData }) => {
    const request = {
      numberOfHours: newData.numberOfHours,
      date: oldData.date,
      userId: data.data.userInfo.userId,
    };

    await addAttendance.mutateAsync(request, {
      onError: (error) => {
        showNotification(error, "error");
      },
      onSettled: () => {
        client.invalidateQueries([jobServiceQueryKeys.GET_JOB_DETAILS, jobId]);
      },
      onSuccess: () => {
        showNotification("Successful", "success");
      },
    });
  };

  return (
    <React.Fragment>
      <span className='mb-5 text-lg text-sky-600 font-bold'>Attendance</span>
      <DataGrid
        dataSource={data.data.attendance}
        key='id'
        showBorders
        columnAutoWidth={false}
        showColumnLines
        showRowLines
        columnHidingEnabled
        rowAlternationEnabled
        allowColumnResizing
        wordWrapEnabled
        onRowUpdating={onRowUpdating}
      >
        <Editing
          allowAdding={false}
          allowUpdating={true}
          allowDeleting={false}
          mode='row'
        />
        <FilterRow visible={true} />

        <HeaderFilter visible={true} />
        <Paging defaultPageSize={10} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={[10, 20, 30]}
          showNavigationButtons={true}
          showInfo={true}
        />

        <Column dataField='date' allowEditing={false} />
        <Column dataField='numberOfHours' dataType='number'>
          <RequiredRule />
        </Column>
        <Summary>
          <TotalItem
            column='numberOfHours'
            displayFormat='Total Hours: {0}'
            summaryType='sum'
          />
        </Summary>
      </DataGrid>
    </React.Fragment>
  );
}
