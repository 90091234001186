import React from "react";
import { MakePayment } from "../../components";

export default function MakePaymentPage() {
  return (
    <React.Fragment>
      <h2 className={"content-block"}>Make Payment</h2>
      <MakePayment />
    </React.Fragment>
  );
}
