import React from "react";
import { CompletedJobs } from "../../components";

export default function CompletedJobsPage() {
  return (
    <React.Fragment>
      <h2 className={"content-block"}>Completed Jobs</h2>
      <CompletedJobs />
    </React.Fragment>
  );
}
