import React, { useState, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  CustomRule,
  PatternRule,
} from "devextreme-react/form";
import LoadIndicator from "devextreme-react/load-indicator";
import logo from "../../images/task_logo.png";
import "./create-account-form.scss";
import { useAuth } from "../../contexts/auth";

export default function CreateAccountForm() {
  const [loading, setLoading] = useState(false);
  const { createAccount } = useAuth();
  const formData = useRef({});

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const { username, password, email, accountType } = formData.current;
      setLoading(true);

      localStorage.setItem("accountType", accountType);

      await createAccount({
        username,
        password,
        email,
        accountType,
      });
      setLoading(false);
    },
    [createAccount]
  );

  const confirmPassword = useCallback(
    ({ value }) => value === formData.current.password,
    []
  );

  return (
    <form className={"create-account-form"} onSubmit={onSubmit}>
      <Link to='/info'>
        <img src={logo} alt='' className='h-8 mx-auto mb-4 cursor-pointer' />
      </Link>
      <Form formData={formData.current} disabled={loading} labelMode='floating'>
        <Item
          dataField={"username"}
          editorType={"dxTextBox"}
          editorOptions={userNameEditorOptions}
        >
          <RequiredRule message='Username is required' />
          <Label visible={false} />
        </Item>
        <Item
          dataField={"email"}
          editorType={"dxTextBox"}
          editorOptions={emailEditorOptions}
        >
          <RequiredRule message='Email is required' />
          <Label visible={false} />
        </Item>
        <Item
          dataField={"password"}
          editorType={"dxTextBox"}
          editorOptions={passwordEditorOptions}
        >
          <RequiredRule message='Password is required' />
          <PatternRule
            pattern={/^(?=.*[a-zA-Z])(?=.*[0-9]).+$/}
            message='Password must be at least 8 characters long and alphanumeric'
          />
          <Label visible={false} />
        </Item>
        <Item
          dataField={"confirmedPassword"}
          editorType={"dxTextBox"}
          editorOptions={confirmedPasswordEditorOptions}
        >
          <RequiredRule message='Password is required' />
          <CustomRule
            message={"Passwords do not match"}
            validationCallback={confirmPassword}
          />
          <Label visible={false} />
        </Item>
        <Item
          dataField={"accountType"}
          editorType={"dxSelectBox"}
          editorOptions={accountTypeEditorOptions}
        >
          <RequiredRule message='Account type is required' />
          <Label visible={false} />
        </Item>
        <Item>
          <div className='policy-info'>
            By creating an account, you agree to the{" "}
            <Link to='#' className='text-sky-500 hover:underline'>
              Terms of Service
            </Link>{" "}
            and{" "}
            <Link to='#' className='text-sky-500 hover:underline'>
              Privacy Policy
            </Link>
          </div>
        </Item>
        <ButtonItem>
          <ButtonOptions
            width={"100%"}
            type={"default"}
            useSubmitBehavior={true}
          >
            <span className='dx-button-text'>
              {loading ? (
                <LoadIndicator width={"24px"} height={"24px"} visible={true} />
              ) : (
                "Create a new account"
              )}
            </span>
          </ButtonOptions>
        </ButtonItem>
        <Item>
          <div className={"login-link"}>
            Have an account?{" "}
            <Link to={"/login"} className='hover:underline'>
              Sign In
            </Link>
          </div>
        </Item>
      </Form>
    </form>
  );
}

const userNameEditorOptions = {
  stylingMode: "outlined",
  placeholder: "Username",
  mode: "text",
};

const emailEditorOptions = {
  stylingMode: "outlined",
  placeholder: "Email",
  mode: "email",
};

const passwordEditorOptions = {
  stylingMode: "outlined",
  placeholder: "Password",
  mode: "password",
};
const confirmedPasswordEditorOptions = {
  stylingMode: "outlined",
  placeholder: "Confirm Password",
  mode: "password",
};

const accountTypeEditorOptions = {
  stylingMode: "outlined",
  placeholder: "Account Type",
  dataSource: [
    { label: "Company", value: "company" },
    { label: "Private Person", value: "individual" },
  ],
  valueExpr: "value",
  displayExpr: "label",
};
