// import notify from "devextreme/ui/notify";
import { Store } from "react-notifications-component";

export const showNotification = (message, type, displayTime = 4000) => {
  if (type === "error") {
    if (message.response) {
      const errorData =
        message.response?.data?.error ||
        "Error performing operation please try again";
      if (Array.isArray(errorData)) {
        errorData.forEach((mes) => {
          // notify(mes, "error", displayTime);
          Store.addNotification({
            message: mes,
            type: "danger",
            insert: "top",
            container: "top-right",
            // animationIn: ["animate__animated", "animate__fadeIn"],
            // animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: displayTime || 6000,
              onScreen: true,
              showIcon: true,
            },
          });
        });
      } else {
        // notify(errorData, "error", displayTime);
        Store.addNotification({
          message: errorData,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: displayTime || 6000,
            onScreen: true,
            showIcon: true,
          },
        });
      }
    } else {
      // showNotification(message, "error", displayTime);
      Store.addNotification({
        message:
          typeof message === "string"
            ? message
            : "Error performing operation please try again",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: displayTime || 6000,
          onScreen: true,
          showIcon: true,
        },
      });
    }
  } else {
    // notify(message, type, displayTime);
    Store.addNotification({
      message,
      type,
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: displayTime || 6000,
        onScreen: true,
        showIcon: true,
      },
    });
  }
  // if (Array.isArray(message)) {
  //   message.forEach((mes) => {
  //     notify(mes, type, displayTime);
  //   });
  // } else {
  //   notify(message, type, displayTime);
  // }
};
