import {
  toDate,
  compareDesc,
  differenceInHours,
  parse,
  format,
} from "date-fns";
import { enCA } from "date-fns/locale";

export const getTimeBlocks = () => {
  const minutesInDay = 1440;
  const timeBlocksArr = [
    { timeString: "12:00 AM", timeValue: "0", disabled: false },
  ];
  for (let i = 30; i <= minutesInDay - 30; i += 30) {
    const halfHourInLoop = i / 60;

    let formattedBlock = String(halfHourInLoop);
    const hour = formattedBlock.split(".")[0];
    const minute = i % 60 === 0 ? "00" : "30";
    formattedBlock = `${hour}:${minute}`;

    const today = new Date();
    const timeString = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      Number(hour),
      Number(minute)
    );
    timeBlocksArr.push({
      timeString: timeString.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      }),
      timeValue: formattedBlock,
      disabled: false,
    });
  }

  return timeBlocksArr;
};

export const setUserToLocalStorage = (token, accountType) => {
  localStorage.setItem(`tp-token-p-${accountType}`, token);
};
export const removeUserFromLocalStaorage = () => {
  localStorage.removeItem(`tp-token-p-${localStorage.getItem("accountType")}`);
  localStorage.removeItem("accountType");
};

export const getToken = () => {
  const accountType = localStorage.getItem("accountType");
  return localStorage.getItem(`tp-token-p-${accountType}`);
};

export const filterRequestData = (keys, requestBody) => {
  const result = {};

  const body = JSON.parse(JSON.stringify(requestBody));

  for (let key in body) {
    if (keys.includes(key)) {
      result[key] = body[key];
    }
  }

  return result;
};

export const convertTime12to24 = (time12h) => {
  const [time, modifier] = time12h.split(" ");

  let [hours, minutes] = time.split(":");

  if (hours === "12") {
    hours = "00";
  }

  if (modifier === "PM") {
    hours = parseInt(hours, 10) + 12;
  }

  return `${hours}:${minutes}`;
};

export const isRichTextEmpty = (value) =>
  value.replace(/<(.|\n)*?>/g, "").trim().length === 0 &&
  !value.includes("<img");

const isNextDay = (startTime, endTime) => {
  const startModifier = startTime.split(" ")[1];
  const endTimeModifier = endTime.split(" ")[1];
  return startModifier === "PM" && endTimeModifier === "AM";
};

export const getNumberOfHours = (startTime, endTime) => {
  const startTime24 = convertTime12to24(startTime);
  const endTime24 = convertTime12to24(endTime);
  const startTimeArray = startTime24.split(":");
  const endTimeArray = endTime24.split(":");

  const day = isNextDay(startTime, endTime) ? 12 : 11;

  const startTimeResult = toDate(
    new Date(
      2022,
      1,
      11,
      parseInt(startTimeArray[0], 10),
      parseInt(startTimeArray[1], 10),
      30
    )
  );

  const endTimeResult = toDate(
    new Date(
      2022,
      1,
      day,
      parseInt(endTimeArray[0], 10),
      parseInt(endTimeArray[1], 10),
      30
    )
  );

  let numberOfHours = 0;

  if (
    (endTimeArray[1] === "30" && startTimeArray[1] === "30") ||
    (endTimeArray[1] === "00" && startTimeArray[1] === "00")
  ) {
    numberOfHours = differenceInHours(endTimeResult, startTimeResult);
  } else {
    numberOfHours = differenceInHours(endTimeResult, startTimeResult) + 1;
  }

  return numberOfHours;
};

export const compareTime = (startTime, endTime) => {
  const startTime24 = convertTime12to24(startTime);
  const endTime24 = convertTime12to24(endTime);
  const startTimeArray = startTime24.split(":");
  const endTimeArray = endTime24.split(":");

  const day = isNextDay(startTime, endTime) ? 12 : 11;

  const startTimeResult = toDate(
    new Date(
      2022,
      1,
      11,
      parseInt(startTimeArray[0], 10),
      parseInt(startTimeArray[1], 10),
      30
    )
  );

  const endTimeResult = toDate(
    new Date(
      2022,
      1,
      day,
      parseInt(endTimeArray[0], 10),
      parseInt(endTimeArray[1], 10),
      30
    )
  );

  let numberOfHours = 0;

  if (
    (endTimeArray[1] === "30" && startTimeArray[1] === "30") ||
    (endTimeArray[1] === "00" && startTimeArray[1] === "00")
  ) {
    numberOfHours = differenceInHours(endTimeResult, startTimeResult);
  } else {
    numberOfHours = differenceInHours(endTimeResult, startTimeResult) + 1;
  }

  // console.log(result);

  return {
    result: compareDesc(startTimeResult, endTimeResult),
    numberOfHours,
  };
};

export const findPercentage = (num, percent) => (num * percent) / 100;

export const formatDate = (date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return year + "/" + month + "/" + day;
};

export const formatCurrency = (val) => {
  return "C$ " + val.toFixed(2);
};

export const isNegative = (number) => {
  return number < 0;
};

export const parseFormattedDate = (dateString) => {
  // Parse the string into a Date object

  const formatString = isValidDateFormatAndReturnFormat(dateString);

  return formatString
    ? parse(dateString, formatString, new Date())
    : dateString;
};

export const isValidDateFormatAndReturnFormat = (dateString) => {
  // Regular expression for the "2023-11-05, 8:11 AM" format
  const regex1 = /^\d{4}-\d{2}-\d{2}, \d{1,2}:\d{2} [APap][Mm]$/;

  // Regular expression for the "2023/11/06 16:07:00" format
  const regex2 = /^\d{4}\/\d{2}\/\d{2} \d{2}:\d{2}:\d{2}$/;

  if (regex1.test(dateString)) {
    return "yyyy-MM-dd, h:mm a";
  }

  if (regex2.test(dateString)) {
    return "yyyy/MM/dd HH:mm:ss";
  }

  return null;
};

export const convertDateFormat = (inputDateString) => {
  // Parse the input date string to a Date object
  const parsedDate = parse(inputDateString, "yyyy/MM/dd HH:mm:ss", new Date());

  // Format the date in the desired format
  const formattedDateString = format(parsedDate, "Pp", {
    locale: enCA,
  });

  return formattedDateString;
};

export const formatDateString = (dateString, formatString) => {
  const date = parseFormattedDate(dateString);

  return format(date, formatString, {
    locale: enCA,
  });
};

export const parseTextToHTML = (text) => {
  // Use a regular expression to find and replace "&lt;" with "<" to decode HTML entities.
  text = text.replace(/&lt;/g, "<");

  return text;
};
