import React from "react";
import { OngoingJobs } from "../../components";

export default function OnGoingJobsPage() {
  return (
    <React.Fragment>
      <h2 className={"content-block"}>Ongoing Jobs</h2>
      <OngoingJobs />
    </React.Fragment>
  );
}
