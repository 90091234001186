import React, { useState } from "react";
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  ColumnChooser,
  Lookup,
  Summary,
  TotalItem,
  MasterDetail,
} from "devextreme-react/data-grid";
import { useMutation, useQueryClient } from "react-query";
import { jobStatusArray } from "../../constants/data";
import { Button } from "devextreme-react";
import JobDetails from "./job-details";
import JobService, { jobServiceQueryKeys } from "../../service/jobService";
import { alert, confirm } from "devextreme/ui/dialog";
import { showNotification } from "../../utils/notification";
import priceCalculator from "../../utils/priceCalculator";
import PaymentModal from "../payment/payment-modal";
import AcceptedApplicants from "./common/AcceptedApplicants";
import CreateJobForm from "./create-job-form";

const yesStatus = [
  { value: true, display: "Yes" },
  { value: false, display: "No" },
];

export default function JobTable({
  dataSource,
  completed,
  paid,
  isPublished,
  isInProgress,
  isAllJobs,
}) {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [jobData, setJobData] = useState(null);
  const [isEditModal, setIsEditModal] = useState(false);
  const [isPaymentModal, setIsPaymentModal] = useState(false);

  const updateJob = useMutation((jobData) =>
    JobService.updateJob(jobData.requestData, jobData.id)
  );

  const deleteJob = useMutation((id) => JobService.deleteJob(id));

  const startJob = useMutation((id) => JobService.startJob(id));

  const completeJob = useMutation(({ id, serviceFee }) =>
    JobService.completeJob(id, serviceFee)
  );

  const client = useQueryClient();

  const closeJobDetails = () => {
    setIsDetailsOpen(false);
    setSelected(null);
  };

  const openPaymentModal = (data) => {
    setJobData(data);
    setIsPaymentModal(true);
  };

  const getJobDetails = async (id) => {
    try {
      const { data } = await JobService.getSingleJob(id);

      setSelected(data);
    } catch (error) {}
  };

  const publishJob = async (id, isGetDetails) => {
    const result = await confirm(
      "Are you sure you want to publish this job ? Applicants will be able to apply to this job if published.",
      "Confirm Job Publish"
    );

    if (result) {
      // requestBody.status = "accepting-application";
      await updateJob.mutateAsync(
        {
          requestData: { status: "accepting-application" },
          id,
        },
        {
          onError: (error) => {
            showNotification(error, "error");
          },
          onSuccess: async () => {
            if (isGetDetails) {
              await getJobDetails(id);
            }

            if (isEditModal) {
              setJobData(null);
              setIsEditModal(false);
            }
            showNotification("Job published successfully", "success");
            client.invalidateQueries(jobServiceQueryKeys.GET_ALL_JOBS, {
              exact: true,
            });

            client.invalidateQueries([
              jobServiceQueryKeys.GET_JOB_STATUS,
              "UN-PUBLISHED",
            ]);

            if (isGetDetails) {
              client.invalidateQueries(jobServiceQueryKeys.GET_JOB_DETAILS, {
                exact: true,
              });
            }
          },
        }
      );
    }
  };

  const handleDeleteJob = async (id) => {
    const result = await confirm(
      "Are you sure you want to delete this job ?",
      "Confirm Job Delete"
    );

    if (result) {
      await deleteJob.mutateAsync(id, {
        onError: (error) => {
          showNotification(error, "error");
        },
        onSuccess: async () => {
          showNotification("Job deleted successfully", "success");
          client.invalidateQueries(jobServiceQueryKeys.GET_ALL_JOBS, {
            exact: true,
          });

          client.invalidateQueries([
            jobServiceQueryKeys.GET_JOB_STATUS,
            "UN-PUBLISHED",
          ]);

          client.invalidateQueries([
            jobServiceQueryKeys.GET_JOB_STATUS,
            "PAID",
          ]);

          client.invalidateQueries([
            jobServiceQueryKeys.GET_JOB_STATUS,
            "PUBLISHED",
          ]);
        },
      });
    }
  };

  const markJobStarted = async (id, isGetDetails) => {
    const result = await confirm(
      "Are you sure you want to start this job ?. This job will no longer receive new applications.",
      "Confirm Job Start"
    );
    if (result) {
      await startJob.mutateAsync(id, {
        onError: (error) => {
          showNotification(error, "error");
        },
        onSuccess: () => {
          showNotification("Job published successfully", "success");
          client.invalidateQueries(jobServiceQueryKeys.GET_ALL_JOBS, {
            exact: true,
          });

          if (isGetDetails) {
            client.invalidateQueries(jobServiceQueryKeys.GET_JOB_DETAILS, {
              exact: true,
            });
          }

          if (isPublished) {
            client.invalidateQueries([
              jobServiceQueryKeys.GET_JOB_STATUS,
              "PUBLISHED",
            ]);
          }
        },
      });
    }
  };

  const markJobCompleted = async (id, isGetDetails, jobData) => {
    const { acceptedApplicants, priceByHour } = jobData;

    const { numberOfWorkers, totalWorkHours } = acceptedApplicants.reduce(
      (acc, cur) => {
        if (cur.totalHours > 0) {
          acc.numberOfWorkers += 1;
        }
        acc.totalWorkHours += cur.totalHours;

        return acc;
      },
      { totalWorkHours: 0, numberOfWorkers: 0 }
    );

    if (totalWorkHours === 0) {
      alert("Please add attendance to complete job", "Notification");
      return;
    }

    const message = `
    <div> 
        <p><strong>Total hours: ${totalWorkHours} </strong> </p> <br/>
        <span>
         Are you sure you want to complete this job ? <br/> 
         Make sure that attendance and hours have been completed. Go to "Add Attendance"  <br />
         page to complete attendance and work hours for each worker.
        </span>
        
    </div>
    
    `;

    const { serviceFee } = priceCalculator({
      numberOfHours: totalWorkHours,
      numberOfWorkers,
      priceByHour,
    });

    const result = await confirm(message, "Confirm Job Complete");
    if (result) {
      await completeJob.mutateAsync(
        { id, serviceFee },
        {
          onError: (error) => {
            showNotification(error, "error");
          },
          onSuccess: () => {
            showNotification("Job completed successfully", "success");
            client.invalidateQueries(jobServiceQueryKeys.GET_ALL_JOBS, {
              exact: true,
            });

            if (isGetDetails) {
              client.invalidateQueries(jobServiceQueryKeys.GET_JOB_DETAILS, {
                exact: true,
              });
            }

            if (isPublished) {
              client.invalidateQueries([
                jobServiceQueryKeys.GET_JOB_STATUS,
                "PUBLISHED",
              ]);
            }

            if (isInProgress) {
              client.invalidateQueries([
                jobServiceQueryKeys.GET_JOB_STATUS,
                "IN-PROGRESS",
              ]);
            }
          },
        }
      );
    }
  };

  const downloadInvoice = async (id) => {
    try {
      const { data } = await JobService.downloadInvoice(id);

      window.open(URL.createObjectURL(data));
    } catch (error) {
      showNotification(error, "error");
    }
  };

  const onToolbarPreparing = (e) => {
    let toolbarItems = e.toolbarOptions.items;
    toolbarItems.forEach(function (item) {
      item.showText = true;

      if (item.name === "searchPanel" || item.name === "columnChooserButton") {
        item.location = "after";
      } else {
        item.location = "before";
      }
    });
  };

  const renderActionButtons = ({ data }) => {
    return (
      <div className='flex sm:flex-row space-x-3 justify-center items-center'>
        <Button
          icon='pi pi-eye'
          type='default'
          hint='View Job Details'
          onClick={() => {
            setSelected(data._id);
            setIsDetailsOpen(true);
          }}
        />

        {(data.status === "new" ||
          data.status === "paid" ||
          data.status === "accepting-application") && (
          <Button
            icon='pi pi-trash'
            type='danger'
            hint='Delete Job'
            onClick={() => {
              if (data.status === "paid" && !data.isPaymentConfirmed) {
                alert(
                  "This job cannot be deleted. Payment have not been confirmed yet."
                );
                return;
              }

              if (data.status === "paid" && !data.acceptedApplicants.length) {
                alert(
                  "This job cannot be deleted. Applicants have been accepted for this job."
                );
                return;
              }

              handleDeleteJob(data._id);
            }}
            // disabled={
            //   data.status === "paid" && !data.isPaymentConfirmed ? true : false
            // }
          />
        )}

        {(data.status === "new" || data.status === "accepting-application") && (
          <Button
            icon='pi pi-pencil'
            hint='Edit Job'
            type='success'
            onClick={() => {
              if (
                data.status === "accepting-application" &&
                data.applicants.length
              ) {
                alert(
                  "You cannot edit a job that already have applications.",
                  "Notification"
                );
                return;
              }
              setJobData(data);
              setIsEditModal(true);
            }}
          />
        )}
      </div>
    );
  };

  // const renderEdit = ({ data }) => {
  //   return <Button icon='pi pi-pencil' hint='Edit Job' type='success' />;
  // };

  const renderButtonType = ({ data }) => {
    switch (data.status) {
      case "in-progress":
        return (
          <Button
            type='success'
            text='Complete Job'
            onClick={() => markJobCompleted(data._id, false, data)}
          />
        );

      case "completed":
        return (
          <div className='flex items-center space-x-2'>
            <Button
              type='default'
              text='Payment'
              onClick={() => {
                setJobData(data);
                setIsPaymentModal(true);
              }}
              hint='Make payment'
            />
            <Button
              type='default'
              stylingMode='outlined'
              text='Invoice'
              hint='Download invoice'
              onClick={() => downloadInvoice(data._id)}
            />
          </div>
        );

      case "new":
        return (
          <Button
            type='default'
            text='Publish Job'
            stylingMode='outlined'
            disabled={updateJob.isLoading}
            onClick={async () => {
              if (!data.isTaskApproved) {
                alert(
                  "You cannot publish this job, it is waiting verification.",
                  "Notification"
                );
                return;
              }
              try {
                await publishJob(data._id);
              } catch (error) {
                console.log({ error });
              }
            }}
          />
        );

      case "accepting-application":
        return (
          <Button
            type='success'
            text='Start Job'
            onClick={() => {
              if (!data.applicants.length) {
                alert(
                  "This job cannot be started. No applications for this job yet.",
                  "Notification"
                );
                return;
              }

              if (!data.acceptedApplicants.length) {
                const message = `
                <div> 
                    
                    <span>
                     No applicant accepted for this job yet. ? <br/> 
                     Go to <strong> Job Applications</strong> to manage applications for this job. <br /> 
                    </span>
                    
                </div>
                
                `;
                alert(message, "Notification");
                return;
              }

              markJobStarted(data._id);
            }}
          />
        );

      default:
        return null;
    }
  };

  return (
    <React.Fragment>
      <DataGrid
        dataSource={dataSource}
        keyExpr='jobCode'
        showBorders
        columnAutoWidth
        showColumnLines
        showRowLines
        columnHidingEnabled
        rowAlternationEnabled
        allowColumnResizing
        wordWrapEnabled
        cellHintEnabled
        onToolbarPreparing={onToolbarPreparing}
        // focusedRowEnabled={completed || paid || isInProgress}
      >
        <FilterRow visible={true} />
        <SearchPanel visible={true} />
        <HeaderFilter visible={true} />
        <Paging defaultPageSize={10} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={[10, 20, 30]}
          showNavigationButtons={true}
          showInfo={true}
        />
        <ColumnChooser enabled={true} mode='select' allowSearch={true} />
        <Column
          dataField='createdAt'
          sortOrder='desc'
          visible={false}
          showInColumnChooser={false}
        />
        <Column dataField='jobCode' />
        <Column dataField='title' />
        <Column
          dataField='startDate'
          visible={completed || paid || isInProgress ? false : true}
        />
        <Column
          dataField='endDate'
          visible={completed || paid || isInProgress ? false : true}
        />
        <Column dataField='numberOfDays' visible={false} />
        <Column dataField='priceByHour' />
        {(completed || paid) && <Column dataField='totalWorkHours' />}
        {(completed || paid) && <Column dataField='totalWorkAmount' />}
        {/* {paid && <Column dataField='totalWorkAmount' />} */}
        {paid && (
          <Column dataField='isPaymentConfirmed'>
            <Lookup
              dataSource={yesStatus}
              displayExpr='display'
              valueExpr='value'
            />
          </Column>
        )}

        {isAllJobs && (
          <Column dataField='status'>
            <Lookup
              dataSource={jobStatusArray}
              valueExpr='value'
              displayExpr='display'
            />
          </Column>
        )}

        {!paid && (
          <Column
            alignment='center'
            cellRender={renderButtonType}
            showInColumnChooser={false}
          />
        )}
        {/* {isNew && (
          <Column
            cellRender={renderEdit}
            alignment='center'
            showInColumnChooser={false}
          />
        )} */}
        <Column
          cellRender={renderActionButtons}
          showInColumnChooser={false}
          allowSearch={false}
          alignment='center'
        />
        {(completed || paid) && (
          <Summary>
            <TotalItem
              column='totalWorkHours'
              displayFormat='Total work hours: {0}'
              summaryType='sum'
            />
            <TotalItem
              column='totalWorkAmount'
              displayFormat='Total work amount: {0}'
              summaryType='sum'
            />
          </Summary>
        )}
        {(completed || paid || isInProgress) && (
          <MasterDetail enabled component={AcceptedApplicants} />
        )}
      </DataGrid>
      {paid && (
        <Column
          alignment='center'
          cellRender={renderButtonType}
          showInColumnChooser={false}
        />
      )}
      <JobDetails
        visible={isDetailsOpen}
        onHide={closeJobDetails}
        id={selected || ""}
        publishJob={publishJob}
        markJobStarted={markJobStarted}
        markJobCompleted={markJobCompleted}
        openPaymentModal={openPaymentModal}
      />
      {jobData && (
        <PaymentModal
          visible={isPaymentModal}
          onHide={() => {
            setJobData(null);
            setIsPaymentModal(false);
          }}
          jobData={jobData}
          downloadInvoice={downloadInvoice}
          closeJobDetails={closeJobDetails}
        />
      )}
      <CreateJobForm
        visible={isEditModal}
        onHide={() => {
          setJobData(null);
          setIsEditModal(false);
        }}
        editingJobData={jobData}
        publishJob={publishJob}
        isModal
      />
    </React.Fragment>
  );
}
