import React from "react";
import { NavigationProvider } from "../contexts/navigation";
import { AuthProvider } from "../contexts/auth";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ReactNotifications } from "react-notifications-component";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 7 * 1000,
      // refetchOnReconnect: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      // refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const Provider = ({ children }) => {
  return (
    <Router>
      <ReactNotifications isMobile />
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <ReactQueryDevtools initialIsOpen={false} />
          <NavigationProvider>{children}</NavigationProvider>
        </AuthProvider>
      </QueryClientProvider>
    </Router>
  );
};

export default Provider;
