import { findPercentage } from "./general";

// return true if in range, otherwise false
const inRange = (number, min, max) => (number - min) * (number - max) <= 0;

const priceCalculator = (values) => {
  const { numberOfHours, numberOfWorkers, pricePerHour } = values;
  let serviceFee = 0;
  let total = 0;
  const grossPayment = numberOfHours * numberOfWorkers * pricePerHour;

  if (inRange(numberOfWorkers, 0, 5)) {
    if (numberOfHours <= 100) {
      serviceFee = 3 * numberOfHours;
    } else {
      const newHour = numberOfHours - 100;
      serviceFee = 100 * 3 + 2.5 * newHour;
    }
  } else if (inRange(numberOfWorkers, 6, 20)) {
    if (numberOfHours <= 300) {
      serviceFee = 2.5 * numberOfHours;
    } else {
      const newHour = numberOfHours - 100;
      serviceFee = 300 * 2.5 + 2 * newHour;
    }
  } else if (inRange(numberOfWorkers, 21, 50)) {
    if (numberOfHours <= 500) {
      serviceFee = 2 * numberOfHours;
    } else {
      const newHour = numberOfHours - 100;
      serviceFee = 500 * 2 + 1.75 * newHour;
    }
  } else {
    serviceFee = 1.75 * numberOfHours;
  }

  const HST = findPercentage(serviceFee, 13);

  total = grossPayment + serviceFee + HST;

  return {
    total,
    grossPayment,
    serviceFee,
    HST,
  };
};

export default priceCalculator;

// https://corporations-ised-isde.api.canada.ca/api/v1/corporations/13612732.json

// ["could not find corporation 123", "Corporation 123 est inconnu."];
