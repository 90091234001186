import React from "react";
import { Hero } from "../../components";

const MainPage = () => {
  return (
    <div>
      <Hero />
    </div>
  );
};

export default MainPage;
