import { Button } from "devextreme-react";
import { useHistory } from "react-router-dom";
import partnerImg from "../../images/pexels-fauxels-3182746.jpg";

const HeroContent = ({ navigateToId }) => {
  const { push } = useHistory();

  return (
    <div className='relative flex flex-col-reverse py-16 lg:pt-0 lg:flex-col lg:pb-0'>
      <div className='inset-y-0 top-0 right-0 z-0 w-full max-w-xl px-4 mx-auto md:px-0 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-7/12 lg:max-w-full lg:absolute xl:px-0'>
        <svg
          className='absolute left-0 hidden h-full text-white transform -translate-x-1/2 lg:block'
          viewBox='0 0 100 100'
          fill='currentColor'
          preserveAspectRatio='none slice'
        >
          <path d='M50 0H100L50 100H0L50 0Z' />
        </svg>
        <img
          className='object-cover w-full h-56 rounded shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full'
          src={partnerImg}
          alt=''
        />
      </div>
      <div className='relative flex flex-col items-start w-full max-w-xl px-4 mx-auto md:px-0 lg:px-8 lg:max-w-screen-xl'>
        <div className='mb-16 lg:my-40 lg:max-w-lg lg:pr-5'>
          <p className='inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-md bg-sky-400'>
            taskpip partner
          </p>
          <h2 className='mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none'>
            Everything you
            <br className='hidden md:block' />
            can imagine{" "}
            <span className='inline-block text-deep-purple-accent-400'>
              is real
            </span>
          </h2>
          <p className='pr-5 mb-5 text-base text-gray-700 md:text-lg'>
            Working together to build a healthy working system and environment
            is our priority. You don’t have to choose between happy and
            motivated workers over profitability when you can have both. Plus,
            don’t hire when there is NO work to be DONE
          </p>
          <div className='flex items-center'>
            <Button
              text='Get started'
              type='default'
              onClick={() => push("/login")}
            />
            <Button
              text='Learn more'
              type='default'
              stylingMode='outlined'
              className='ml-4'
              onClick={navigateToId}
            />
            {/* <a
              className='dx-widget dx-button dx-button-mode-outlined dx-button-default dx-button-has-text '
              href='#more'
            >
              <div className='dx-button-content'>
                <span className='dx-button-text'>Learn more</span>
              </div>
            </a> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroContent;
