import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { SingleCard } from "./layouts";
import {
  LoginForm,
  ResetPasswordForm,
  ChangePasswordForm,
  CreateAccountForm,
} from "./components";
import MainPage from "./pages/main/MainPage";

export default function UnauthenticatedContent() {
  return (
    <Switch>
      <Route exact path='/info'>
        <MainPage />
      </Route>
      <Route exact path='/login'>
        <SingleCard title='Sign In'>
          <LoginForm />
        </SingleCard>
      </Route>
      <Route exact path='/create-account'>
        <SingleCard title='Sign Up'>
          <CreateAccountForm />
        </SingleCard>
      </Route>
      <Route exact path='/forgot-password'>
        <SingleCard
          title='Forgot Password ?'
          description='Please enter the email address that you used to register, and we will send you a link to reset your password via Email.'
        >
          <ResetPasswordForm />
        </SingleCard>
      </Route>
      <Route exact path='/password-reset'>
        <SingleCard title='Change Password'>
          <ChangePasswordForm />
        </SingleCard>
      </Route>
      <Redirect to={"/info"} />
    </Switch>
  );
}
