import React, { useState } from "react";
import Alert from "../misc/Alert";
import { Link } from "react-router-dom";
import { Button, LoadPanel, SelectBox } from "devextreme-react";
import { useQuery, useQueryClient } from "react-query";
import JobService, { jobServiceQueryKeys } from "../../service/jobService";
import { showNotification } from "../../utils/notification";
import { findPercentage, formatCurrency } from "../../utils/general";
import { confirm } from "devextreme/ui/dialog";

export default function MakePayment() {
  const { data, isLoading, isError, error } = useQuery(
    [jobServiceQueryKeys.GET_JOB_STATUS, "COMPLETED"],
    async () => JobService.getAllJobs("completed")
  );

  const client = useQueryClient();

  const [jobData, setJobData] = useState(null);

  if (isLoading) {
    return <LoadPanel visible />;
  }

  if (isError) {
    showNotification(error, "error");
  }

  const downloadInvoice = async (id) => {
    try {
      const { data } = await JobService.downloadInvoice(id);

      window.open(URL.createObjectURL(data));
    } catch (error) {
      showNotification(error, "error");
    }
  };

  const confirmPayment = async (id) => {
    const result = await confirm(
      "Are you sure you want to confirm payment made for this job ?",
      "Confirm"
    );

    if (result) {
      try {
        await JobService.confirmPayment(id);
        setJobData(null);
        client.invalidateQueries([
          jobServiceQueryKeys.GET_JOB_STATUS,
          "COMPLETED",
        ]);
      } catch (error) {
        showNotification(error, "error");
      }
    }
  };

  return (
    <React.Fragment>
      <div className='content-block'>
        <Alert>
          <span className='text-base'>
            Here you can view invoice and manage payment for compeleted jobs.
            Please make sure that job have been completed. Go{" "}
            <Link to='/ongoing-jobs'>Here </Link>
            to mark job as completed.
          </span>
        </Alert>
      </div>
      <div className={"content-block dx-card responsive-paddings"}>
        <SelectBox
          label='Select a job to make payment'
          labelMode='floating'
          stylingMode='outlined'
          dataSource={data.data}
          showDropDownButton
          showClearButton
          // showSelectionControls
          displayExpr='title'
          // valueExpr='_id'
          searchEnabled
          onValueChanged={({ value }) => {
            setJobData(value);
          }}
        />
      </div>

      {jobData && (
        <div className={"content-block dx-card responsive-paddings"}>
          <div className='flex items-center sm:justify-between sm:flex-row flex-col'>
            <h6>Payment Details</h6>
            <div className='flex items-center space-x-3'>
              <Button
                text='Invoice'
                type='default'
                stylingMode='outlined'
                onClick={() => downloadInvoice(jobData._id)}
              />
              <Button
                text='Mark Payment made'
                type='default'
                onClick={() => confirmPayment(jobData._id)}
              />
            </div>
          </div>

          <div className='mt-10 border-b border-t py-4'>
            <p className='text-base font-bold mb-5'>Invoice Details</p>
            <div className='flex items-center space-x-5'>
              <span className='text-base sm:w-96'>Invoice number:</span>
              <strong className='text-base'>{jobData.invoiceNumber}</strong>
            </div>
            <div className='flex items-center space-x-5'>
              <span className='text-base sm:w-96'>Invoice date:</span>
              <span className='text-base'>{jobData.invoiceDate}</span>
            </div>
            <div className='flex items-center space-x-5'>
              <span className='text-base sm:w-96'>Job code:</span>
              <strong className='text-base'>{jobData.jobCode}</strong>
            </div>
            <div className='flex items-center space-x-5'>
              <span className='text-base sm:w-96'>Job title:</span>
              <span className='text-base'>{jobData.title}</span>
            </div>
            <div className='flex items-center space-x-5'>
              <span className='text-base sm:w-96'>Price by hour:</span>
              <span className='text-base'>{jobData.priceByHour}</span>
            </div>
            <div className='flex items-center space-x-5'>
              <span className='text-base sm:w-96'>Total hours:</span>
              <span className='text-base'>{jobData.totalWorkHours}</span>
            </div>
            <div className='flex items-center space-x-5'>
              <span className='text-base sm:w-96'>
                Total work amount ( price by hour * total hours ):
              </span>
              <span className='text-base'>
                {formatCurrency(jobData.totalWorkAmount)}
              </span>
            </div>
            <div className='flex items-center space-x-5'>
              <span className='text-base sm:w-96'>Service fee:</span>
              <span className='text-base'>
                {formatCurrency(jobData.serviceFee)}
              </span>
            </div>
            <div className='flex items-center space-x-5'>
              <span className='text-base sm:w-96'>
                HST ( 13% of service fee ) *:
              </span>
              <span className='text-base'>
                {formatCurrency(
                  jobData.hasHST ? findPercentage(jobData.serviceFee, 13) : 0
                )}
              </span>
            </div>

            <div className='flex items-center space-x-5 mt-8'>
              <span className='text-base sm:w-96 font-bold'>Balance due:</span>
              <strong className='text-base'>
                {formatCurrency(
                  jobData.totalWorkAmount + jobData.serviceFee + jobData.hasHST
                    ? findPercentage(jobData.serviceFee, 13)
                    : 0
                )}
              </strong>
            </div>
            <p className=''>
              Payment is due within 3 working days of completing job.
            </p>
          </div>

          <div className='mt-8'>
            <p className='text-base font-bold mb-5'>
              Payment instruction and options
            </p>
            <span className='font-bold'>Interact payment </span>
            <p className='mb-4 text-base '>
              Interact payment also known as email money transfer –
              <strong> payments@taskpip.com</strong>
            </p>
            <span className='font-bold'>Direct deposit </span>
            <div className='flex items-center space-x-2'>
              <span className='text-base'>Account number:</span>
              <span className='text-base font-bold'> 552360139319</span>
            </div>
            <div className='flex items-center space-x-2'>
              <span className='text-base'>Transit number:</span>
              <span className='text-base font-bold'> 55236</span>
            </div>
            <div className='flex items-center space-x-2'>
              <span className='text-base'>Institution numberr:</span>
              <span className='text-base font-bold'> 002</span>
            </div>
            <p className='mt-4'>
              Please add <strong>{`"Job code"`}</strong> as reference during
              payment.
            </p>
            <p className='mt-4'>
              * HST only applies to selected province and will not be added if
              not applicable to the job province.
            </p>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
