export const isProd = process.env.NODE_ENV === "production";

export const API_URL = isProd
  ? "https://taskpip-backend.onrender.com"
  : "http://localhost:8080";

export const CLIENT_URL = isProd
  ? "https://taskpip.com"
  : "http://localhost:3000";

// https://taskpip-backend.onrender.com

// https://taskpip-api-sandbox.herokuapp.com
