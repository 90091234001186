import React, { useRef } from "react";
import { Calculator, HomeHeader } from "..";
import HeroFooter from "./Footer";
import HeroContent from "./HeroContent";
import Pricing from "./Pricing";

const Hero = () => {
  const moreRef = useRef(null);
  const navigateToId = () => {
    if (moreRef) {
      moreRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <HomeHeader />
      <HeroContent navigateToId={navigateToId} />
      <Pricing isPadding moreRef={moreRef} />
      <Calculator />
      <HeroFooter />
      {/* <Footer>
        Copyright © 2022-{new Date().getFullYear()} {appInfo.title}
      </Footer> */}
    </>
  );
};

export default Hero;
