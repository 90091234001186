import React from "react";
import { PublishedJobs } from "../../components";

export default function PublishedJobsPage() {
  return (
    <React.Fragment>
      <h2 className={"content-block"}>Published Jobs</h2>
      <PublishedJobs />
    </React.Fragment>
  );
}
