import React, { useState, useRef, useCallback } from "react";
import Form, {
  SimpleItem,
  GroupItem,
  RequiredRule,
  CustomRule,
  ButtonItem,
  ButtonOptions,
  PatternRule,
} from "devextreme-react/form";
import AuthService from "../../service/authService";
import { showNotification } from "../../utils/notification";

export default function GeneralSettings() {
  const [loading, setLoading] = useState(false);
  const formData = useRef({});
  const formRef = useRef(null);

  const onSubmit = async (e) => {
    e.preventDefault();
    const { oldPassword, newPassword } = formData.current;
    setLoading(true);
    try {
      await AuthService.updatePassword({
        currentPassword: oldPassword,
        newPassword,
      });
      setLoading(false);
      showNotification("Password updated successfully", "success");
      formRef.current.instance.resetValues();
    } catch (error) {
      showNotification(error, "error");
      setLoading(false);
    }
  };

  const confirmPassword = useCallback(
    ({ value }) => value === formData.current.newPassword,
    []
  );

  return (
    <div className={"content-block dx-card responsive-paddings"}>
      <form className='sm:w-1/2 w-full' onSubmit={onSubmit}>
        <Form
          formData={formData.current}
          labelLocation='top'
          labelMode='floating'
          ref={formRef}
          disabled={loading}
        >
          <GroupItem colCount={1} caption='Change Password'>
            <SimpleItem
              dataField='oldPassword'
              editorOptions={{
                mode: "password",
                inputAttr: { autocomplete: "old-password" },
                stylingMode: "outlined",
              }}
            >
              <RequiredRule />
            </SimpleItem>
            <SimpleItem
              dataField='newPassword'
              editorOptions={{
                mode: "password",
                inputAttr: { autocomplete: "new-password" },
                stylingMode: "outlined",
              }}
            >
              <RequiredRule />
              <PatternRule
                pattern={/^(?=.*[a-zA-Z])(?=.*[0-9]).+$/}
                message='Password must be at least 8 characters long and alphanumeric'
              />
            </SimpleItem>
            <SimpleItem
              dataField='confirmPassword'
              editorOptions={{
                mode: "password",
                inputAttr: { autocomplete: "confirm-password" },
                stylingMode: "outlined",
              }}
            >
              <RequiredRule />
              <CustomRule
                message={"Passwords do not match"}
                validationCallback={confirmPassword}
              />
            </SimpleItem>
            <ButtonItem>
              <ButtonOptions
                width={"100%"}
                type={"default"}
                useSubmitBehavior={true}
                text='Change Password'
              />
            </ButtonItem>
          </GroupItem>
        </Form>
        {/* <Button type='default' text='Change Password' useSubmitBehavior /> */}
      </form>
    </div>
  );
}
