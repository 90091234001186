import React from "react";
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  ColumnChooser,
  Editing,
} from "devextreme-react/data-grid";
import { showNotification } from "../../utils/notification";
import ProfileService from "../../service/profileService";

const ProfileDocData = ({ idDocURLS }) => {
  const renderDownload = (e) => {
    return <i className='dx-icon-download text-blue-500 cursor-pointer'></i>;
  };

  const onRowRemoving = async ({ key }) => {
    try {
      await ProfileService.deleteFile(key);
      showNotification("File removed successfully", "success");
    } catch (error) {
      showNotification(error, "error");
    }
  };

  return (
    <div>
      <DataGrid
        dataSource={idDocURLS}
        keyExpr='id'
        showBorders={true}
        wordWrapEnabled={true}
        columnAutoWidth={true}
        showColumnLines={true}
        showRowLines={true}
        columnHidingEnabled={true}
        rowAlternationEnabled={true}
        onRowRemoving={onRowRemoving}
      >
        <Paging defaultPageSize={5} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={[5, 10, 15]}
          showNavigationButtons={true}
          showInfo={true}
        />
        <ColumnChooser enabled={true} mode='select' allowSearch={true} />
        <Editing
          allowDeleting={true}
          allowAdding={false}
          allowUpdating={false}
          mode='row'
          useIcons={true}
        />
        <FilterRow visible={true} />
        <SearchPanel visible={true} />
        <HeaderFilter visible={true} />

        <Column dataField='type' caption='Document type' />
        <Column dataField='fileName' caption='Document name' />
        <Column
          showInColumnChooser={false}
          allowFiltering={false}
          allowSorting={false}
          cellRender={renderDownload}
          alignment='center'
          width={80}
        />
      </DataGrid>
    </div>
  );
};

export default ProfileDocData;
