export const navigation = [
  {
    text: "Home",
    path: "/home",
    icon: "pi pi-th-large",
  },
  {
    text: "Profile",
    path: "/profile",
    icon: "pi pi-user-edit",
  },
  {
    text: "Jobs",
    icon: "pi pi-briefcase",
    items: [
      {
        text: "Create Job",
        path: "/create-job",
      },
      {
        text: "Job Applications",
        path: "/job-applications",
      },
      {
        text: "All Jobs",
        path: "/jobs",
      },

      {
        text: "Un-published Jobs",
        path: "/un-published-jobs",
      },

      {
        text: "Published Jobs",
        path: "/published-jobs",
      },

      {
        text: "Ongoing Jobs",
        path: "/ongoing-jobs",
      },
      {
        text: "Completed Jobs",
        path: "/completed-jobs",
      },
      {
        text: "Paid Jobs",
        path: "/paid-jobs",
      },
    ],
  },
  {
    text: "Job Attendance",
    icon: "pi pi-clock",
    items: [
      {
        text: "Add Attendance",
        path: "/attendance",
      },
    ],
  },
  {
    text: "Payments",
    icon: "pi pi-credit-card",
    items: [
      {
        text: "Make Payment",
        path: "/payment",
      },

      // {
      //   text: "Invoices",
      //   path: "/invoices",
      // },
    ],
  },
  {
    text: "Settings",
    icon: "pi pi-cog",
    path: "/settings",
  },
];
