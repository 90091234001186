import React, { useState, useRef, useEffect, useCallback } from "react";
import Form, {
  SimpleItem,
  GroupItem,
  ButtonItem,
  ButtonOptions,
} from "devextreme-react/form";
import { ScrollView, Button } from "devextreme-react";
import { Popup, ToolbarItem } from "devextreme-react/popup";
import JobDays from "./jobDays";
import Dropzone from "react-dropzone-uploader";
import ad from "../../images/ad.svg";
import { alert, confirm } from "devextreme/ui/dialog";
import { useAuth } from "../../contexts/auth";
import {
  parseFormattedDate,
  getToken,
  isRichTextEmpty,
  isNegative,
} from "../../utils/general";
import { API_URL } from "../../constants";
import { showNotification } from "../../utils/notification";
import { format, max, min, differenceInHours } from "date-fns";
import JobService, { jobServiceQueryKeys } from "../../service/jobService";
import { useMutation, useQueryClient } from "react-query";
import RichTextEditor from "react-rte";
import Alert from "../misc/Alert";
import { enCA } from "date-fns/locale";
// import RichEditor from "../customEditor/ckeditor";
import { provinceArray } from "../../constants/data";

// "title",
// "fullDescription",
// "shortDescription",
// "numberOfDays",
// "isPermanent",
// "location",
// "isPaidByHour",
// "priceByHour",
// "price",
// "status",
// "totalWorkHours",
// "totalWorkAmount",
// "jobImgUrl",
// "jobImgId",
// "jobPosterName",
// "taskDays",
// "isCanceled",
// "reasonForCancel",
// "startDate",
// "endDate",

export default function CreateJobForm({
  isModal,
  visible,
  onHide,
  editingJobData,
  publishJob,
}) {
  const [formData, setFormData] = useState({
    title: "",
    shortDescription: "",
    priceByHour: null,
    fullDescription: RichTextEditor.createEmptyValue(),
    addressLine: "",
    city: "",
    postalCode: "",
    province: "",
    country: "Canada",
  });
  const [jobAdImage, setJobAdImage] = useState(null);
  const { user } = useAuth();
  const client = useQueryClient();

  const grid = useRef(null);

  const createJob = useMutation((jobData) => {
    return JobService.createJob(jobData);
  });

  const upDateJob = useMutation(({ jobData, id }) => {
    return JobService.updateJob(jobData, id);
  });

  useEffect(() => {
    if (user && user.profile && !editingJobData) {
      if (user.accountType === "company") {
        setFormData({ ...formData, ...user.profile.contactDetails[0].address });
      } else {
        setFormData({ ...formData, ...user.profile.address });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, editingJobData]);

  useEffect(() => {
    if (editingJobData) {
      const newData = {
        title: editingJobData.title,
        shortDescription: editingJobData.shortDescription,
        priceByHour: editingJobData.priceByHour,
        fullDescription: RichTextEditor.createValueFromString(
          editingJobData.fullDescription || "",
          "markdown"
        ),
        ...editingJobData.location,
      };

      setFormData({ ...newData });

      if (editingJobData.jobImgUrl && editingJobData.jobImgId) {
        setJobAdImage({
          URL: editingJobData.jobImgUrl,
          id: editingJobData.jobImgId,
        });
      }
    }
  }, [editingJobData]);

  const renderDescription = useCallback(
    () => (
      <>
        <span className='text-base'>
          <span className='text-base text-gray-400'>Full Description</span>{" "}
          <span className='text-gray-600'>*</span>
        </span>
        <Alert>
          <span className='text-base'>
            Alternatively, you can copy and paste your completed description
            from a document editor like microsoft or google docs here or use the
            editor below for quick action.
          </span>
        </Alert>
        <RichTextEditor
          onChange={(value) => {
            setFormData((prev) => ({ ...prev, fullDescription: value }));
          }}
          value={formData.fullDescription}
          placeholder='Enter job full description'
          editorClassName='editor'
        />

        {/* <RichEditor
          onChange={(value) => {
            setFormData((prev) => ({ ...prev, fullDescription: value }));
          }}
          value={formData.fullDescription}
        /> */}
      </>
    ),
    [setFormData, formData.fullDescription]
  );

  const getUploadParams = async ({ file }) => {
    let body = new FormData();

    body.append("file", file);
    body.append("type", "Job Ad");
    body.append("fileName", file.name);

    return {
      url: `${API_URL}/api/v1/user/profile/file`,
      headers: { token: getToken() },
      body,
    };
  };

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, remove, xhr }, status) => {
    if (status === "error_upload") {
      showNotification(
        meta ? `${meta.name}, upload failed...` : "Error uploading file",
        "error"
      );
    }

    if (status === "exception_upload") {
      showNotification(
        meta ? `${meta.name}, upload failed...` : "Error uploading file",
        "error"
      );
    }

    if (status === "aborted") {
      showNotification(`${meta.name}, upload failed...`, "error");
    }

    if (status === "done") {
      const { data } = JSON.parse(xhr.response);
      setJobAdImage(data);
      showNotification(`${meta.name} uploaded successfully`, "success");
      remove();
    }
  };

  const onSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }
    let isError = false;
    let isTimeError = false;
    let isPastDate = false;

    //eslint-disable-next-line
    for (const [_, value] of Object.entries(formData)) {
      if (!value) {
        isError = true;
      }
    }

    const dayItems = grid.current.instance.getDataSource().items();

    if (
      isError ||
      !dayItems?.length ||
      isRichTextEmpty(formData.fullDescription.toString("html"))
    ) {
      alert("All fields are required", "Notification");
      return;
    }

    const newDays = [];

    // console.log({ dayItems });

    dayItems.forEach((d) => {
      const start =
        typeof d.startTime === "string"
          ? parseFormattedDate(d.startTime)
          : d.startTime;
      const end =
        typeof d.endTime === "string"
          ? parseFormattedDate(d.endTime)
          : d.endTime;
      const numberOfHours = differenceInHours(end, start);
      const isInValidHours = isNegative(numberOfHours) || numberOfHours === 0;
      if (isInValidHours) {
        isTimeError = true;
        return;
      }

      // if (isBefore(d.date, Date.now()) && !isSameDay(d.date, Date.now())) {
      //   isPastDate = true;
      //   return;
      // }

      const newObject = {
        ...d,
        startTime: format(
          editingJobData ? new Date(d.startTime) : d.startTime,
          "Pp",
          {
            locale: enCA,
          }
        ),
        endTime: format(
          editingJobData ? new Date(d.endTime) : d.endTime,
          "Pp",
          {
            locale: enCA,
          }
        ),
        date: format(editingJobData ? new Date(d.date) : d.date, "P", {
          locale: enCA,
        }),
        numberOfHours,
      };

      newDays.push(newObject);
    });

    if (isTimeError) {
      alert(
        "Start time cannot be greater that End time, end time cannot be less than the start time and start time and end time cannot be the same",
        "Notification"
      );
      return;
    }

    if (isPastDate) {
      alert("Date cannot be in the past", "Notification");
      return;
    }

    const requestBody = {
      ...formData,
      fullDescription: formData.fullDescription.toString("markdown"),
      location: {
        addressLine: formData.addressLine,
        city: formData.city,
        postalCode: formData.postalCode,
        province: formData.province,
        country: formData.country,
      },
      hasHST: formData.province.trim().toLowerCase() === "ontario",
      taskDays: newDays,
      startDate: format(
        min(dayItems.map((e) => (editingJobData ? new Date(e.date) : e.date))),
        "P",
        {
          locale: enCA,
        }
      ),
      endDate: format(
        max(dayItems.map((e) => (editingJobData ? new Date(e.date) : e.date))),
        "P",
        {
          locale: enCA,
        }
      ),
      numberOfDays: dayItems.length,
    };

    if (jobAdImage) {
      requestBody.jobImgUrl = jobAdImage.URL;
      requestBody.jobImgId = jobAdImage.id;
    }

    if (!editingJobData && user.accountType === "company") {
      const result = await confirm(
        "Do you want to publish Job immediately after it have been created ?",
        "Confirm Job Publish"
      );

      if (result) {
        requestBody.status = "accepting-application";
      }
    }

    if (editingJobData) {
      await upDateJob.mutateAsync(
        { jobData: requestBody, id: editingJobData._id },
        {
          onError: (error) => {
            showNotification(error, "error");
          },
          onSuccess: () => {
            showNotification("Job updated successfully", "success");
            client.invalidateQueries(jobServiceQueryKeys.GET_ALL_JOBS);
            client.invalidateQueries([
              jobServiceQueryKeys.GET_JOB_STATUS,
              "UN-PUBLISHED",
            ]);
          },
        }
      );
    } else {
      await createJob.mutateAsync(requestBody, {
        onError: (error) => {
          showNotification(error, "error");
        },
        // onSettled: () => {
        //   setFormData({ ...formData, ...initialState });
        //   setJobAdImage(null);
        //   grid.current.instance.refresh();
        // },
        onSuccess: () => {
          setFormData((prev) => ({
            ...prev,
            title: "",
            shortDescription: "",
            priceByHour: null,
            fullDescription: RichTextEditor.createEmptyValue(),
          }));
          setJobAdImage(null);
          grid.current.instance.refresh();
          if (requestBody.status) {
            showNotification(
              "Job successfully created and published",
              "success"
            );
          } else {
            showNotification(
              "Job successfully created. Remember to publish job to start receiving applications",
              "success"
            );
          }
          client.invalidateQueries(jobServiceQueryKeys.GET_ALL_JOBS);
        },
      });
    }
  };

  return (
    <React.Fragment>
      {isModal ? (
        <Popup
          visible={visible}
          onHiding={onHide}
          title='Edit Job'
          animation={null}
          fullScreen
          showCloseButton
        >
          <ScrollView>
            <div className='p-3'>
              {user?.profile ? (
                <>
                  {user.accountType === "individual" ? (
                    <>
                      {user.profile.profileStatus === "active" ? (
                        <>
                          <div className={""}>
                            <Alert>
                              <span className='text-base'>
                                Don't forget to click the{" "}
                                <strong>SAVE CHANGES</strong> button to save
                                changes.
                              </span>
                            </Alert>
                          </div>
                          <div
                            className={
                              "content-block dx-card responsive-paddings flex sm:flex-row flex-col items-center sm:space-y-0 space-y-5 sm:space-x-6 space-x-0"
                            }
                          >
                            <div className='border p-3 rounded w-1/2'>
                              <img
                                alt={""}
                                src={jobAdImage?.URL || ad}
                                className='object-cover w-full'
                              />
                            </div>
                            <Dropzone
                              getUploadParams={getUploadParams}
                              onChangeStatus={handleChangeStatus}
                              canRemove={true}
                              maxSizeBytes={7340032}
                              multiple={false}
                              maxFiles={1}
                              inputContent='Click here to add job ad image (Optional)'
                              accept='image/*'
                            />
                          </div>
                          <div
                            className={
                              "content-block dx-card responsive-paddings mt-3"
                            }
                          >
                            <form onSubmit={onSubmit}>
                              <Form
                                labelLocation='top'
                                labelMode='floating'
                                formData={formData}
                                showRequiredMark={false}
                                disabled={createJob.isLoading}
                              >
                                <GroupItem colCount={2}>
                                  <SimpleItem
                                    dataField='title'
                                    editorOptions={{ stylingMode: "outlined" }}
                                  />

                                  <SimpleItem
                                    dataField='priceByHour'
                                    editorType='dxNumberBox'
                                    editorOptions={{
                                      stylingMode: "outlined",
                                      min: 1,
                                      showSpinButtons: true,
                                    }}
                                  />
                                </GroupItem>
                                <GroupItem>
                                  <SimpleItem
                                    dataField='shortDescription'
                                    editorType='dxTextArea'
                                    editorOptions={{
                                      stylingMode: "outlined",
                                      hint: "Please enter a short description of the job",
                                      autoResizeEnabled: true,
                                    }}
                                  />
                                </GroupItem>
                                <GroupItem>
                                  <SimpleItem render={renderDescription} />
                                </GroupItem>
                                <GroupItem caption='Job Location' colCount={2}>
                                  <SimpleItem
                                    dataField='addressLine'
                                    label={{ text: "Address Line" }}
                                    editorOptions={{ stylingMode: "outlined" }}
                                  />
                                  <SimpleItem
                                    dataField='city'
                                    label={{ text: "City" }}
                                    editorOptions={{ stylingMode: "outlined" }}
                                  />
                                  <SimpleItem
                                    editorType='dxSelectBox'
                                    dataField='province'
                                    label={{ text: "Province" }}
                                    editorOptions={{
                                      stylingMode: "outlined",
                                      dataSource: provinceArray,
                                    }}
                                  />
                                  <SimpleItem
                                    dataField='postalCode'
                                    label={{ text: "Postal Code" }}
                                    editorOptions={{ stylingMode: "outlined" }}
                                  />
                                  <SimpleItem
                                    dataField='country'
                                    label={{ text: "Country" }}
                                    editorOptions={{
                                      stylingMode: "outlined",
                                      readOnly: true,
                                    }}
                                  />
                                </GroupItem>
                                <GroupItem>
                                  <SimpleItem
                                    render={() => (
                                      <JobDays
                                        isEditAble
                                        gridRef={grid}
                                        daysData={
                                          editingJobData?.taskDays || []
                                        }
                                        isEditingJob
                                      />
                                    )}
                                  />
                                </GroupItem>
                              </Form>
                            </form>
                          </div>
                        </>
                      ) : (
                        <div
                          className={
                            "content-block dx-card responsive-paddings"
                          }
                        >
                          {user.profile.profileStatus === "pending" ? (
                            <Alert className='!bg-yellow-200 !text-yellow-900'>
                              <span className='text-base'>
                                Sorry you cannot create a job now. Profile is
                                awaiting verification. Our team is working on
                                that. It usually takes 1 working day if all
                                documents are correct.
                              </span>
                            </Alert>
                          ) : (
                            <Alert className='!bg-red-300 !text-red-800'>
                              <span className='text-base'>
                                Sorry you cannot create a job now. Profile is
                                currently de-activated. Please contact the
                                support team for help.
                              </span>
                            </Alert>
                          )}
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {user?.profile.status === "Active" ? (
                        <>
                          <div className={""}>
                            <Alert>
                              <span className='text-base'>
                                Don't forget to click the{" "}
                                <strong>SAVE CHANGES</strong> button to save
                                changes.
                              </span>
                            </Alert>
                          </div>
                          <div
                            className={
                              "content-block dx-card responsive-paddings flex sm:flex-row flex-col items-center sm:space-y-0 space-y-5 sm:space-x-6 space-x-0"
                            }
                          >
                            <div className='border p-3 rounded w-1/2'>
                              <img
                                alt={""}
                                src={jobAdImage?.URL || ad}
                                className='object-cover w-full'
                              />
                            </div>
                            <Dropzone
                              getUploadParams={getUploadParams}
                              onChangeStatus={handleChangeStatus}
                              canRemove={true}
                              maxSizeBytes={7340032}
                              multiple={false}
                              maxFiles={1}
                              inputContent='Click here to add job ad image (Optional)'
                              accept='image/*'
                            />
                          </div>
                          <div
                            className={
                              "content-block dx-card responsive-paddings mt-3"
                            }
                          >
                            <form onSubmit={onSubmit}>
                              <Form
                                labelLocation='top'
                                labelMode='floating'
                                formData={formData}
                                showRequiredMark={false}
                                disabled={createJob.isLoading}
                              >
                                <GroupItem colCount={2}>
                                  <SimpleItem
                                    dataField='title'
                                    editorOptions={{ stylingMode: "outlined" }}
                                  />

                                  <SimpleItem
                                    dataField='priceByHour'
                                    editorType='dxNumberBox'
                                    editorOptions={{
                                      stylingMode: "outlined",
                                      min: 1,
                                      showSpinButtons: true,
                                    }}
                                  />
                                </GroupItem>
                                <GroupItem>
                                  <SimpleItem
                                    dataField='shortDescription'
                                    editorType='dxTextArea'
                                    editorOptions={{
                                      stylingMode: "outlined",
                                      hint: "Please enter a short description of the job",
                                      autoResizeEnabled: true,
                                    }}
                                  />
                                </GroupItem>
                                <GroupItem>
                                  <SimpleItem render={renderDescription} />
                                </GroupItem>
                                <GroupItem caption='Job Location' colCount={2}>
                                  <SimpleItem
                                    dataField='addressLine'
                                    label={{ text: "Address Line" }}
                                    editorOptions={{ stylingMode: "outlined" }}
                                  />
                                  <SimpleItem
                                    dataField='city'
                                    label={{ text: "City" }}
                                    editorOptions={{ stylingMode: "outlined" }}
                                  />
                                  <SimpleItem
                                    editorType='dxSelectBox'
                                    dataField='province'
                                    label={{ text: "Province" }}
                                    editorOptions={{
                                      stylingMode: "outlined",
                                      dataSource: provinceArray,
                                    }}
                                  />
                                  <SimpleItem
                                    dataField='postalCode'
                                    label={{ text: "Postal Code" }}
                                    editorOptions={{ stylingMode: "outlined" }}
                                  />
                                  <SimpleItem
                                    dataField='country'
                                    label={{ text: "Country" }}
                                    editorOptions={{
                                      stylingMode: "outlined",
                                      readOnly: true,
                                    }}
                                  />
                                </GroupItem>
                                <GroupItem>
                                  <SimpleItem
                                    render={() => (
                                      <JobDays
                                        isEditAble
                                        gridRef={grid}
                                        daysData={
                                          editingJobData?.taskDays || []
                                        }
                                        isEditingJob
                                      />
                                    )}
                                  />
                                </GroupItem>
                              </Form>
                            </form>
                          </div>
                        </>
                      ) : (
                        <div
                          className={
                            "content-block dx-card responsive-paddings"
                          }
                        >
                          <Alert className='!bg-red-300 !text-red-800'>
                            <span className='text-base'>
                              Business status is not active. Please confirm
                              business registration status to start creating
                              jobs.
                            </span>
                          </Alert>
                        </div>
                      )}
                    </>
                  )}
                </>
              ) : (
                <div className={"content-block dx-card responsive-paddings"}>
                  <Alert className='!bg-yellow-200 !text-yellow-900'>
                    <span className='text-base'>
                      {`Please complete ${
                        user.accountType === "company"
                          ? "business profile"
                          : "profile"
                      } to start creating jobs.`}
                    </span>
                  </Alert>
                </div>
              )}
            </div>
          </ScrollView>
          {user?.profile && editingJobData && (
            <ToolbarItem
              location='after'
              render={() => (
                <Button
                  text='Save Changes'
                  type='default'
                  onClick={() => onSubmit()}
                />
              )}
            />
          )}
          {user?.profile && editingJobData?.status === "new" && (
            <ToolbarItem
              location='after'
              render={() => (
                <Button
                  text='Publish job'
                  stylingMode='outlined'
                  type='default'
                  onClick={() => {
                    if (!editingJobData?.isTaskApproved) {
                      alert(
                        "You cannot publish this job, it is waiting verification.",
                        "Notification"
                      );
                      return;
                    }
                    publishJob(editingJobData?._id);
                  }}
                />
              )}
            />
          )}
        </Popup>
      ) : (
        <>
          {user?.profile ? (
            <>
              {user.accountType === "individual" ? (
                <>
                  {user.profile.profileStatus === "active" ? (
                    <>
                      <div
                        className={
                          "content-block dx-card responsive-paddings flex sm:flex-row flex-col items-center sm:space-y-0 space-y-5 sm:space-x-6 space-x-0"
                        }
                      >
                        <div className='border p-3 rounded w-1/2'>
                          <img
                            alt={""}
                            src={jobAdImage?.URL || ad}
                            className='object-cover w-full'
                          />
                        </div>
                        <Dropzone
                          getUploadParams={getUploadParams}
                          onChangeStatus={handleChangeStatus}
                          canRemove={true}
                          maxSizeBytes={7340032}
                          multiple={false}
                          maxFiles={1}
                          inputContent='Click here to add job ad image (Optional)'
                          accept='image/*'
                        />
                      </div>
                      <div
                        className={
                          "content-block dx-card responsive-paddings mt-3"
                        }
                      >
                        <form onSubmit={onSubmit}>
                          <Form
                            labelLocation='top'
                            labelMode='floating'
                            formData={formData}
                            showRequiredMark={false}
                            disabled={createJob.isLoading}
                          >
                            <GroupItem colCount={2}>
                              <SimpleItem
                                dataField='title'
                                editorOptions={{ stylingMode: "outlined" }}
                              />

                              <SimpleItem
                                dataField='priceByHour'
                                editorType='dxNumberBox'
                                editorOptions={{
                                  stylingMode: "outlined",
                                  min: 1,
                                  showSpinButtons: true,
                                }}
                              />
                            </GroupItem>
                            <GroupItem>
                              <SimpleItem
                                dataField='shortDescription'
                                editorType='dxTextArea'
                                editorOptions={{
                                  stylingMode: "outlined",
                                  hint: "Please enter a short description of the job",
                                  autoResizeEnabled: true,
                                }}
                              />
                            </GroupItem>
                            <GroupItem>
                              <SimpleItem render={renderDescription} />
                            </GroupItem>
                            <GroupItem caption='Job Location' colCount={2}>
                              <SimpleItem
                                dataField='addressLine'
                                label={{ text: "Address Line" }}
                                editorOptions={{ stylingMode: "outlined" }}
                              />
                              <SimpleItem
                                dataField='city'
                                label={{ text: "City" }}
                                editorOptions={{ stylingMode: "outlined" }}
                              />
                              <SimpleItem
                                editorType='dxSelectBox'
                                dataField='province'
                                label={{ text: "Province" }}
                                editorOptions={{
                                  stylingMode: "outlined",
                                  dataSource: provinceArray,
                                }}
                              />
                              <SimpleItem
                                dataField='postalCode'
                                label={{ text: "Postal Code" }}
                                editorOptions={{ stylingMode: "outlined" }}
                              />
                              <SimpleItem
                                dataField='country'
                                label={{ text: "Country" }}
                                editorOptions={{
                                  stylingMode: "outlined",
                                  readOnly: true,
                                }}
                              />
                            </GroupItem>
                            <GroupItem>
                              <SimpleItem
                                render={() => (
                                  <JobDays isEditAble gridRef={grid} />
                                )}
                              />
                            </GroupItem>
                            <ButtonItem>
                              <ButtonOptions
                                width={"100%"}
                                type={"default"}
                                useSubmitBehavior={true}
                                text='Save Job'
                              />
                            </ButtonItem>
                          </Form>
                        </form>
                      </div>
                    </>
                  ) : (
                    <div
                      className={"content-block dx-card responsive-paddings"}
                    >
                      {user.profile.profileStatus === "pending" ? (
                        <Alert className='!bg-yellow-200 !text-yellow-900'>
                          <span className='text-base'>
                            Sorry you cannot create a job now. Profile is
                            awaiting verification. Our team is working on that.
                            It usually takes 1 working day if all documents are
                            correct.
                          </span>
                        </Alert>
                      ) : (
                        <Alert className='!bg-red-300 !text-red-800'>
                          <span className='text-base'>
                            Sorry you cannot create a job now. Profile is
                            currently de-activated. Please contact the support
                            team for help.
                          </span>
                        </Alert>
                      )}
                    </div>
                  )}
                </>
              ) : (
                <>
                  {user?.profile.status === "Active" ? (
                    <>
                      <div
                        className={
                          "content-block dx-card responsive-paddings flex sm:flex-row flex-col items-center sm:space-y-0 space-y-5 sm:space-x-6 space-x-0"
                        }
                      >
                        <div className='border p-3 rounded w-1/2'>
                          <img
                            alt={""}
                            src={jobAdImage?.URL || ad}
                            className='object-cover w-full'
                          />
                        </div>
                        <Dropzone
                          getUploadParams={getUploadParams}
                          onChangeStatus={handleChangeStatus}
                          canRemove={true}
                          maxSizeBytes={7340032}
                          multiple={false}
                          maxFiles={1}
                          inputContent='Click here to add job ad image (Optional)'
                          accept='image/*'
                        />
                      </div>
                      <div
                        className={
                          "content-block dx-card responsive-paddings mt-3"
                        }
                      >
                        <form onSubmit={onSubmit}>
                          <Form
                            labelLocation='top'
                            labelMode='floating'
                            formData={formData}
                            showRequiredMark={false}
                            disabled={createJob.isLoading}
                          >
                            <GroupItem colCount={2}>
                              <SimpleItem
                                dataField='title'
                                editorOptions={{ stylingMode: "outlined" }}
                              />

                              <SimpleItem
                                dataField='priceByHour'
                                editorType='dxNumberBox'
                                editorOptions={{
                                  stylingMode: "outlined",
                                  min: 1,
                                  showSpinButtons: true,
                                }}
                              />
                            </GroupItem>
                            <GroupItem>
                              <SimpleItem
                                dataField='shortDescription'
                                editorType='dxTextArea'
                                editorOptions={{
                                  stylingMode: "outlined",
                                  hint: "Please enter a short description of the job",
                                  autoResizeEnabled: true,
                                }}
                              />
                            </GroupItem>
                            <GroupItem>
                              <SimpleItem render={renderDescription} />
                            </GroupItem>
                            <GroupItem caption='Job Location' colCount={2}>
                              <SimpleItem
                                dataField='addressLine'
                                label={{ text: "Address Line" }}
                                editorOptions={{ stylingMode: "outlined" }}
                              />
                              <SimpleItem
                                dataField='city'
                                label={{ text: "City" }}
                                editorOptions={{ stylingMode: "outlined" }}
                              />
                              <SimpleItem
                                editorType='dxSelectBox'
                                dataField='province'
                                label={{ text: "Province" }}
                                editorOptions={{
                                  stylingMode: "outlined",
                                  dataSource: provinceArray,
                                }}
                              />
                              <SimpleItem
                                dataField='postalCode'
                                label={{ text: "Postal Code" }}
                                editorOptions={{ stylingMode: "outlined" }}
                              />
                              <SimpleItem
                                dataField='country'
                                label={{ text: "Country" }}
                                editorOptions={{
                                  stylingMode: "outlined",
                                  readOnly: true,
                                }}
                              />
                            </GroupItem>
                            <GroupItem>
                              <SimpleItem
                                render={() => (
                                  <JobDays isEditAble gridRef={grid} />
                                )}
                              />
                            </GroupItem>
                            <ButtonItem>
                              <ButtonOptions
                                width={"100%"}
                                type={"default"}
                                useSubmitBehavior={true}
                                text='Save Job'
                              />
                            </ButtonItem>
                          </Form>
                        </form>
                      </div>
                    </>
                  ) : (
                    <div
                      className={"content-block dx-card responsive-paddings"}
                    >
                      <Alert className='!bg-red-300 !text-red-800'>
                        <span className='text-base'>
                          Business status is not active. Please confirm business
                          registration status to start creating jobs.
                        </span>
                      </Alert>
                    </div>
                  )}
                </>
              )}
            </>
          ) : (
            <div className={"content-block dx-card responsive-paddings"}>
              <Alert className='!bg-yellow-200 !text-yellow-900'>
                <span className='text-base'>
                  {`Please complete ${
                    user.accountType === "company"
                      ? "business profile"
                      : "profile"
                  } to start creating jobs.`}
                </span>
              </Alert>
            </div>
          )}
        </>
      )}
    </React.Fragment>
  );
}
