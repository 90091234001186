import React from "react";
import { useQuery } from "react-query";
import JobService, { jobServiceQueryKeys } from "../../service/jobService";
import { LoadPanel } from "devextreme-react";
import JobTable from "./job-table";
import { showNotification } from "../../utils/notification";
import Alert from "../misc/Alert";

const UnPublishedJobs = () => {
  const { error, data, isLoading, isError } = useQuery(
    [jobServiceQueryKeys.GET_JOB_STATUS, "UN-PUBLISHED"],
    async () => JobService.getAllJobs("new")
  );

  if (isLoading) {
    return <LoadPanel visible />;
  }

  if (isError) {
    showNotification(error, "error");
  }

  return (
    <React.Fragment>
      <div className='content-block'>
        <Alert>
          <span className='text-base'>
            Click on <strong>PUBLISH J0B</strong> to make job visible to
            applicants and start receiving applications for the job.
          </span>
        </Alert>
      </div>
      <div className={"content-block dx-card responsive-paddings"}>
        <JobTable dataSource={data?.data || []} />
      </div>
    </React.Fragment>
  );
};
export default UnPublishedJobs;
